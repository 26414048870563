import {AppState} from "../store";
import {
  ILeadCollectionDisplayStore,
  ILeadCollectionStoreFieldErrors,
  ILeadCollectionSubmissionSpinStore,
  ILeadCollectionSubmissionStore,
} from "./lead-collection.slice";
import {createSelector} from "@reduxjs/toolkit";
import {isDefined} from "is-type-util";
import {SpinWheelItemValue} from "../../config/spin-wheel";

const getLeadCollectionStore = (store: AppState) =>
  store.leadCollection;

export const getIsSpinWheelEnabled = (store: AppState): boolean =>
  getLeadCollectionStore(store).isSpinWheelEnabled;
export const getLeadCollectionValues = (store: AppState) =>
  getLeadCollectionStore(store).values;
export const getLeadCollectionErrors = (store: AppState): ILeadCollectionStoreFieldErrors | undefined =>
  getLeadCollectionStore(store).errors;

export const getLeadCollectionShouldFormAutocomplete = (store: AppState): boolean =>
  getLeadCollectionStore(store).shouldFormAutocomplete ?? false;
export const getLeadCollectionIsAuthenticated = (store: AppState): boolean =>
  getLeadCollectionStore(store).isAuthenticated ?? false;
export const getLeadCollectionEventId = (store: AppState): number | undefined =>
  getLeadCollectionStore(store).eventId;
export const getLeadCollectionKioskId = (store: AppState): number | undefined =>
  getLeadCollectionStore(store).kioskId;

export const getIsLeadCollectionKioskReady = createSelector(
  [getLeadCollectionIsAuthenticated, getLeadCollectionEventId, getLeadCollectionKioskId],
  (isAuthenticated, eventId, kioskId) => {
    return (
      isAuthenticated
      && isDefined(eventId)
      && isDefined(kioskId)
    )
  }
)

const getLeadCollectionDisplayStore = (store: AppState): ILeadCollectionDisplayStore | undefined =>
  getLeadCollectionStore(store).display;

export const getIsLeadCollectionTermsModalOpen = (store: AppState): boolean =>
  getLeadCollectionDisplayStore(store)?.isTermsModalOpen ?? false;
export const getIsLeadCollectionThankYouModalOpen = (store: AppState): boolean =>
  getLeadCollectionDisplayStore(store)?.isThankYouModalOpen ?? false;
export const getIsLeadCollectionSpinWheelModalOpen = (store: AppState): boolean =>
  getLeadCollectionDisplayStore(store)?.isSpinWheelModalOpen ?? false;
export const getIsLeadCollectionSpinWheelResultsModalOpen = (store: AppState): boolean =>
  getLeadCollectionDisplayStore(store)?.isSpinWheelResultsModalOpen ?? false;

export const getLeadCollectionSubmitDetails = (store: AppState): ILeadCollectionSubmissionStore | undefined =>
  getLeadCollectionStore(store).submitDetails;
export const getLeadCollectionSpinStore = (store: AppState): ILeadCollectionSubmissionSpinStore | undefined =>
  getLeadCollectionSubmitDetails(store)?.spin;
export const getShouldWheelSpin = (store: AppState): boolean =>
  getLeadCollectionSpinStore(store)?.shouldSpin ?? false;
export const getWheelSpinResults = (store: AppState): SpinWheelItemValue | undefined =>
  getLeadCollectionSpinStore(store)?.value;

export const getDoesWheelHaveSpinResults = (store: AppState): boolean =>
  isDefined(getWheelSpinResults(store));

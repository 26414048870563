import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {getIsSyncInfoModalActive} from "../../../redux/display/display.selectors";
import {useCallback} from "react";
import {deactivateModal} from "../../../redux/display/display.slice";


export interface ISyncInfoModalLogic {
  isOpen: boolean;
  handleClose: () => void;
}

export const useSyncInfoModalLogic = (): ISyncInfoModalLogic => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(getIsSyncInfoModalActive);
  const handleClose = useCallback(() => {
    dispatch(deactivateModal(`sync-info`))
  }, [dispatch]);

  return {
    isOpen,
    handleClose,
  };
}

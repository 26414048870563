import type {LeadCollectionReducer} from "../lead-collection.slice";

export interface ISetKioskAuthenticationPayload {
  isAuthenticated: true;
  eventId: number;
  kioskId: number;
}
export interface ISetKioskUnAuthenticatedPayload {
  isAuthenticated: false;
}
export const setKioskAuthenticationReducer: LeadCollectionReducer<ISetKioskAuthenticationPayload | ISetKioskUnAuthenticatedPayload> = (store, action) => {

  const {isAuthenticated} = action.payload;
  if (!isAuthenticated) {
    return {
      ...store,
      isAuthenticated,
      eventId: undefined,
      kioskId: undefined,
    };
  }

  const {kioskId, eventId} = action.payload;
  const isStoreAlreadyUpdated = (
    store.isAuthenticated === isAuthenticated
    && store.eventId === eventId
    && store.kioskId === kioskId
  );
  if (isStoreAlreadyUpdated) {
    // store already current, nothing to update
    return store;
  }

  return {
    ...store,
    isAuthenticated,
    eventId,
    kioskId,
  };
}


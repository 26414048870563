import {FunctionComponent,} from "react";
import s from "./LeadCollectionForm.module.scss";
import {FormInputItem} from "../form-input-item/FormInputItem";
import {useLeadCollectionFormLogic} from "./LeadCollectionForm.logic";
import {SystemCollectorType} from "../../../redux/system/system.slice";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";


export interface ILeadCollectionFormProps {
  collector: SystemCollectorType;
}
export const LeadCollectionForm: FunctionComponent<ILeadCollectionFormProps> = ({collector,}) => {
  const {
    handleSubmit,
    handleReset,
    data,
    errors,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    handlePhoneChange,
    handleAgreeToTermsChange,
    shouldFormAutocomplete,
  } = useLeadCollectionFormLogic(collector);
  return (
    <main className={s.container}>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div className={s.details}>
          <FormInputItem
            title={`First Name`}
            inputType={'text'}
            inputPlaceholder={`John`}
            isRequired
            shouldAutocomplete={shouldFormAutocomplete}
            autocompleteType={`given-name`}
            value={data.firstName}
            onChangeHandler={handleFirstNameChange}
            errorMessage={errors.firstName}
          />
          <FormInputItem
            title={`Last Name`}
            inputType={"text"}
            inputPlaceholder={`Doe`}
            isRequired
            shouldAutocomplete={shouldFormAutocomplete}
            autocompleteType={"family-name"}
            value={data.lastName}
            onChangeHandler={handleLastNameChange}
            errorMessage={errors.lastName}
          />

          <FormInputItem
            title={`Phone Number`}
            inputType={"phone"}
            inputPlaceholder={`888-888-8888`}
            shouldAutocomplete={shouldFormAutocomplete}
            autocompleteType={"tel"}
            value={data.phone}
            onChangeHandler={handlePhoneChange}
            errorMessage={errors.phone}
          />
          <FormInputItem
            title={`Email`}
            inputType={"email"}
            inputPlaceholder={`john.doe@example.com`}
            isRequired
            shouldAutocomplete={shouldFormAutocomplete}
            autocompleteType={`email`}
            value={data.email}
            onChangeHandler={handleEmailChange}
            errorMessage={errors.email}
          />

        </div>
        <div className={s.agree}>
          <label className={s.agreeLabel}>
            <Checkbox required checked={data.agreeToTerms} onChange={handleAgreeToTermsChange}/>
            <span className={s.agreeText}>By checking this box, I consent to receive marketing communications including email and text messages. These messages may be delivered via through an automatic email and or telephone dialing systems at the number and or email address provided. Consent is not a condition to purchase. Text STOP to unsubscribe or HELP for help. Msg and data rates may apply. Check our privacy policy</span>
          </label>
        </div>
        <div className={s.actions}>
          <Button
            variant="outlined"
            size={"small"}
            color={"inherit"}
            className={s.actionSecondary}
            type={"reset"}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            size={"small"}
            className={s.actionPrimary}
            color={"inherit"}
            type={"submit"}
          >
            Submit
          </Button>
        </div>
      </form>
    </main>
  );
}


import {AppState} from "../store";
import {ISyncLeadStats, ISyncStore} from "./sync.slice";

const getSyncStore = (store: AppState): ISyncStore =>
  store.sync;

export const getSyncStats = (store: AppState): ISyncLeadStats =>
  getSyncStore(store).stats ?? {};

export const getSyncTotalStat = (store: AppState): number =>
  getSyncStats(store).total ?? 0;
export const getSyncPendingStat = (store: AppState): number =>
  getSyncStats(store).pending ?? 0;
export const getSyncSendingStat = (store: AppState): number =>
  getSyncStats(store).sending ?? 0;
export const getSyncCompleteStat = (store: AppState): number =>
  getSyncStats(store).complete ?? 0;
export const getSyncFailedStat = (store: AppState): number =>
  getSyncStats(store).failed ?? 0;

export const getHasSyncPending = (store: AppState): boolean =>
  !!getSyncPendingStat(store);
export const getHasSyncSending = (store: AppState): boolean =>
  !!getSyncSendingStat(store);
export const getHasSyncFailed = (store: AppState): boolean =>
  !!getSyncFailedStat(store);

export const getActiveKioskId = (store: AppState): number | undefined =>
  getSyncStore(store).activeKiosk;

export const getIsActiveKioskAuthenticated = (store: AppState): boolean =>
  getSyncStore(store).isAuthenticated ?? false;

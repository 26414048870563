
import {useEffect} from "react";
import {activeKioskService} from "../../services/event/active-kiosk-service";
import {kioskService} from "../../services/event/kiosk-service";
import {isUndefined} from "is-type-util";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {getIsLeadCollectionKioskReady} from "../../redux/lead-collection/lead-collection.selectors";
import {productMonitoring} from "../../services/logging/product-monitoring";

export interface IKioskPortalLogic {
  isKioskReady: boolean;
}



export const useKioskPortalLogic = () => {
  const isKioskReady = useAppSelector(getIsLeadCollectionKioskReady);
  const dispatch = useAppDispatch();


  useEffect(() => {
    activeKioskService.getKiosk()
      .then((activeKiosk) => {
        if (isUndefined(activeKiosk)){
          // no kiosk, nothing we can do
          return;
        }
        productMonitoring.identify(activeKiosk.eventId, activeKiosk.id);

        void kioskService.authenticate(activeKiosk.eventId, activeKiosk.id, activeKiosk.token);
      })
  }, [dispatch]);

  return {
    isKioskReady,
  };
}

import {FunctionComponent} from "react";
import s from "./SettingsModal.module.scss";
import Modal from "../modal/Modal";
import {useSettingsModalLogic} from "./SettingsModal.logic";
import {SettingsForm} from "../../settings/form/SettingsForm";
import {SpinWheelSettings} from "../../settings/spin-wheel/SpinWheelSettings";
import {SettingsCurrent} from "../../settings/current/SettingsCurrent";


export const SettingsModal: FunctionComponent = () => {
  const {isOpen, handleClose} = useSettingsModalLogic();
  return (
    <Modal
      title={`Settings`}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className={s.container}>
        <SettingsForm />
        <div className={s.switches}>
          <SettingsCurrent />
          <SpinWheelSettings />
        </div>

      </div>
    </Modal>
  );
}

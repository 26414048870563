import type {PayloadAction} from "@reduxjs/toolkit";
import type {ILeadCollectionStore, ILeadCollectionStoreValues, LeadCollectionStoreField,} from "../lead-collection.slice";
import {initialState} from "../lead-collection.initialState";

export interface ILeadCollectionSetFieldPayload <PayloadFieldName extends LeadCollectionStoreField>{
  field: PayloadFieldName;
  value: ILeadCollectionStoreValues[PayloadFieldName];
}
export const setFieldReducer = <FieldNameType extends LeadCollectionStoreField = LeadCollectionStoreField>(store: ILeadCollectionStore, action: PayloadAction<ILeadCollectionSetFieldPayload<FieldNameType>>): ILeadCollectionStore => {
  const {field, value} = action.payload;
  return {
    ...store,
    values: {
      ...store.values,
      [field]: value,
    },
    errors: {
      ...store.errors,
      // reset field error to initial
      [field]: initialState.errors?.[field],
    }
  };
};

import {createSlice, type PayloadAction} from "@reduxjs/toolkit";



export interface ISyncEvent {
  id: number;
  name: string;
}
export interface ISyncKiosk {
  id: number;
  eventId: number;
  token: string;
}

export interface ISyncLeadStats {
  total?: number;
  pending?: number;
  sending?: number;
  complete?: number;
  failed?: number;
}
export interface ISyncStore {
  isAuthenticated?: boolean;
  activeKiosk?: number;
  events?: Record<number, ISyncEvent>;
  kiosks?: Record<number, ISyncKiosk>;
  stats?: ISyncLeadStats;
}

export const initialState: ISyncStore = {

};

export const syncSlice = createSlice({
  name: `sync`,
  initialState,
  reducers: {
    resetSync: (store) => {
      return {
        ...initialState,
      };
    },
    addEvent: (store, action: PayloadAction<ISyncEvent>) => {
      const event = action.payload;
      return {
        ...store,
        events: {
          ...store.events,
          [event.id]: {
            ...event,
          },
        },
      };
    },
    addKiosk: (store, action: PayloadAction<ISyncKiosk>) => {
      const kiosk = action.payload;
      return {
        ...store,
        kiosks: {
          ...store.kiosks,
          [kiosk.id]: {
            ...kiosk,
          },
        },
      };
    },
    updateStats: (store, action: PayloadAction<Partial<ISyncLeadStats>>) => {
      const newStats = action.payload;
      return {
        ...store,
        stats: {
          ...store.stats,
          ...newStats,
        },
      };
    },
    setActiveKioskAuthenticated: (store, action: PayloadAction<boolean>) => {
      const isAuthenticated = action.payload;
      return {
        ...store,
        isAuthenticated,
      };
    },
    setActiveKiosk: (store, action: PayloadAction<number | undefined>) => {
      const activeKiosk = action.payload;
      if (activeKiosk === store.activeKiosk) {
        // already set as active kiosk, return unchanged
        return store;
      }

      return {
        ...store,
        activeKiosk,
        // active kiosk changed, so update isAuthenticated
        isAuthenticated: false,
      }
    }
  },
});

export const {
  resetSync,
  addKiosk,
  addEvent,
  updateStats,
  setActiveKiosk,
  setActiveKioskAuthenticated,
} = syncSlice.actions;

export const syncReducer = syncSlice.reducer;

import {AbstractPulseClient} from "./abstract-client";
import type {IPulseClientProvider} from "./providers/pulse-client-provider";

export interface IEventKioskCreateData {
    name: string;
}
export interface IEventKioskUpdateData {
    name: string;
}
export class EventKiosksClient extends AbstractPulseClient <number, IEventKioskCreateData, IEventKioskUpdateData> {

  constructor(eventId: number, provider?: IPulseClientProvider) {
    super(`/events/${eventId}/kiosks`, provider);
  }

  public async getToken(identifier: number)
  {
      const requestUrl = `${this.rootPath}/${identifier}/token`;
      return await this.instance
          .get<{token: string}>(requestUrl);
  }

}

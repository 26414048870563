import {FunctionComponent} from "react";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useSpinWheelSettingsLogic} from "./SpinWheelSettings.logic";

export const SpinWheelSettings: FunctionComponent = () => {
  const {isChecked, handleChange} = useSpinWheelSettingsLogic();

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={isChecked} onChange={handleChange} color={isChecked ? `success` : undefined} />}
        label={`Spin Wheel ${isChecked ? 'En' : `Dis`}abled`}
      />
    </FormGroup>
  )
}

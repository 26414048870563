import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import {isDefined} from "is-type-util";
import {localStorageService} from "../storage/local-storage";
import {v4 as uuidV4} from "uuid"
import {buildVersion} from "../../config/environment";
import {productMonitoringFeatureFlag} from "../../config/feature-flags";

const deviceIdentifierStorageKey = `DEVICE_IDENTIFIER_UUID`;

const getOrCreateDeviceIdentifier = (): string => {
  const deviceUuidFromStorage = localStorageService.getJsonItem<string>(deviceIdentifierStorageKey);
  if (isDefined(deviceUuidFromStorage)) {
    return deviceUuidFromStorage;
  }
  const newUuid = uuidV4();
  localStorageService.setJsonItem<string>(deviceIdentifierStorageKey, newUuid);
  return newUuid;
}

export interface IProductMonitoringCaptureExceptionOptions {
  tags?: {
    [tagName: string]: string | number | boolean,
  },
  extra?: {
    [tagName: string]: string | number | boolean,
  },
}

class ProductMonitoring {
  protected readonly deviceIdentifier: string;
  constructor() {
    this.deviceIdentifier = getOrCreateDeviceIdentifier();
    if (!productMonitoringFeatureFlag){
      // nothing to do, return to exit
      return;
    }
    LogRocket.init(
      '5jeyeu/pulse',
      {
        release: buildVersion,
      }
    );
    setupLogRocketReact(LogRocket);

    LogRocket.identify(this.deviceIdentifier);
  }

  public identify(eventId: number, kioskId: number)
  {
    if (!productMonitoringFeatureFlag){
      // nothing to do, return to exit
      return;
    }
    LogRocket.identify({
      eventId,
      kioskId,
    });
  }

  public log(message: string, ...other: any[])
  {
    if (!productMonitoringFeatureFlag){
      // nothing to do, return to exit
      return;
    }

    LogRocket.log(message, ...other);
  }

  public error(message: string, ...other: any[])
  {
    if (!productMonitoringFeatureFlag){
      // nothing to do, return to exit
      return;
    }

    LogRocket.error(message, ...other);
  }

  public track(eventName: string, eventProperties?: Record<string, string | number | boolean | string[] | number[] | boolean[] | undefined>): void
  {
    if (!productMonitoringFeatureFlag){
      // nothing to do, return to exit
      return;
    }

    LogRocket.track(eventName, eventProperties)
  }

  public captureException(exception: Error, options?: IProductMonitoringCaptureExceptionOptions)
  {
    if (!productMonitoringFeatureFlag){
      // nothing to do, return to exit
      return;
    }

    LogRocket.captureException(exception, options);
  }

}

export const productMonitoring = new ProductMonitoring();

import {AbstractPulseClient} from "./abstract-client";
import {AxiosResponse,} from "axios";
import {EventKioskProvider} from "./providers/event-kiosk-provider";

export interface IEventKioskLeadCreateData {
    uuid: string;
    first_name: string;
    last_name: string;
    email: string;
    phone?: string;
    submitted_at: number;
}

export class EventKioskLeadsClient extends AbstractPulseClient <number, IEventKioskLeadCreateData, never> {

  constructor(eventId: number, kioskId: number, token: string) {
    super(`/events/${eventId}/kiosks/${kioskId}/leads`, new EventKioskProvider(token));
  }

  async getList<RequestFilters = Record<string, unknown>, ResponseType = unknown>(filters?: RequestFilters): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }

  async deleteOne<ResponseType = unknown>(identifier: number): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }

  async updateOne<ResponseType = unknown>(identifier: number, data: never): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }

}

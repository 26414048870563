import type {LeadCollectionReducer} from "../lead-collection.slice";
import type {SpinWheelItemValue} from "../../../config/spin-wheel";

export interface ISetSubmissionDetailsPayload {
  id: number;
  uuid: string;
  submittedAt: number;
}
export const setSubmissionDetailsReducer: LeadCollectionReducer<ISetSubmissionDetailsPayload> = (store, action) => {
  const {id, uuid, submittedAt} = action.payload;
  return {
    ...store,
    submitDetails: {
      ...store.submitDetails,
      id,
      uuid,
      submittedAt,
    }
  }
}

export interface ISpinWheelResultsPayload {
  value: SpinWheelItemValue;
  isSpinAgain?: boolean;
}

export const setIsSpinWheelEnabledReducer: LeadCollectionReducer<boolean> = (store, action) => {
  const isSpinWheelEnabled = action.payload;
  return {
    ...store,
    isSpinWheelEnabled,
  }
}
export const setSpinWheelResultsReducer: LeadCollectionReducer<ISpinWheelResultsPayload> = (store, action) => {
  const {isSpinAgain, value} = action.payload;

  return {
    ...store,
    submitDetails: {
      ...store.submitDetails!,
      spin: {
        value,
        isSpinAgain,
        shouldSpin: false,
      },
    },
  };

}

import {useLiveQuery} from "dexie-react-hooks";
import {KioskRepository} from "../../../repositories/events/kiosk-repository";
import {useCallback, useState} from "react";
import {Kiosk} from "../../../database/models/Kiosk";
import {isUndefined} from "is-type-util";
import {SelectChangeEvent} from "@mui/material";
import {GridRowSelectionModel} from "@mui/x-data-grid";
import {KioskLeadRepository} from "../../../repositories/events/kiosk-lead-repository";
import {LeadStatus} from "../../../database/models/Lead";


const kioskRepository: KioskRepository = new KioskRepository();
const leadRepository: KioskLeadRepository = new KioskLeadRepository();
export interface ILeadListToolbarUpdateKioskLogic {
  kioskId?: number;
  kiosks?: Kiosk[];
  handleKioskChange: (event: SelectChangeEvent<number>) => void;
  handleKioskSaveUpdate: () => void;
  status?: LeadStatus;
  handleStatusChange: (event: SelectChangeEvent<LeadStatus>) => void;
  handleStatusSaveUpdate: () => void;
}

export const useLeadListToolbarUpdateKioskLogic = (selection: GridRowSelectionModel): ILeadListToolbarUpdateKioskLogic => {


  const [kioskId, setKioskId] = useState<number|undefined>();

  const kiosks = useLiveQuery<Kiosk[]>(() => kioskRepository.getAll());

  const handleKioskChange = useCallback((event: SelectChangeEvent<number>) => {
    const newValue = event.target.value as number;
    setKioskId(newValue);
  }, [setKioskId]);

  const handleKioskSaveUpdate = useCallback(() => {
    if (isUndefined(kioskId)) {
      // nothing to do
      return;
    }

    kioskRepository.getForId(kioskId)
      .then((kiosk) => {

        leadRepository.bulkUpdateKiosk(
          kiosk!.eventId,
          kioskId,
          selection as number[]
        );
      });
  }, [kioskId, selection]);


  const [status, setStatus] = useState<LeadStatus|undefined>();

  const handleStatusChange = useCallback((event: SelectChangeEvent<LeadStatus>) => {
    setStatus(event.target.value as LeadStatus);
  }, [setStatus]);

  const handleStatusSaveUpdate = useCallback(() => {
    if (isUndefined(status)) {
      // nothing to do
      return;
    }

    leadRepository.bulkUpdateStatus(
      status!,
      selection as number[]
    );
  }, [status, selection]);

  return {
    kioskId,
    kiosks,
    handleKioskChange,
    handleKioskSaveUpdate,
    status,
    handleStatusChange,
    handleStatusSaveUpdate,
  }
}

import {configureStore} from "@reduxjs/toolkit";
import {connectionReducer} from "./connection/connection.slice";
import {leadCollectionReducer} from "./lead-collection/lead-collection.slice";
import {systemReducer} from "./system/system.slice";
import {syncReducer} from "./sync/sync.slice";
import LogRocket from "logrocket";
import {displayReducer} from "./display/display.slice";


export const store = configureStore({
  reducer: {
    connection: connectionReducer,
    display: displayReducer,
    leadCollection: leadCollectionReducer,
    system: systemReducer,
    sync: syncReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(LogRocket.reduxMiddleware())
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;

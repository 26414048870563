import {ChangeEvent, FormEvent, useCallback, useEffect} from "react";
import {leadCollectionService} from "../../../services/event/lead-collection/lead-collection-service";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
  getLeadCollectionErrors, getLeadCollectionShouldFormAutocomplete,
  getLeadCollectionValues
} from "../../../redux/lead-collection/lead-collection.selectors";
import {
  resetForm,
  setDisplayModalStatus,
  setField,
  setFormCollector
} from "../../../redux/lead-collection/lead-collection.slice";
import {SystemCollectorType} from "../../../redux/system/system.slice";
import {ReduxStateService} from "../../../services/state/redux-state";


export interface ILeadCollectionFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  agreeToTerms: boolean;
}

export interface ILeadCollectionFormLogic {
  shouldFormAutocomplete: boolean;

  data: ILeadCollectionFormData;
  errors: Partial<Record<keyof ILeadCollectionFormData, string>>;

  handleReset: () => void | Promise<void>;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void | Promise<void>;
  handleFirstNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleLastNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handlePhoneChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleAgreeToTermsChange: (event: ChangeEvent<HTMLInputElement>) => void;
}
export const useLeadCollectionFormLogic = (collector: SystemCollectorType): ILeadCollectionFormLogic => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(getLeadCollectionValues);
  const errors = useAppSelector(getLeadCollectionErrors) ?? {};

  const shouldFormAutocomplete = useAppSelector(getLeadCollectionShouldFormAutocomplete);

  useEffect(() => {
    dispatch(setFormCollector(collector));
  }, [dispatch, collector,]);

  const handleReset = useCallback(() => {
    dispatch(resetForm());
  }, [dispatch,]);

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.currentTarget.checkValidity();
    const {firstName, lastName, email, phone, agreeToTerms} = data;
    if (!agreeToTerms) {
      // not valid
      return;
    }
    await leadCollectionService.collectLead(collector, {
      firstName,
      lastName,
      email,
      phone,
    });
    
  }, [data, collector]);

  const handleFirstNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setField({field: `firstName`, value: event.currentTarget.value}));
  }, [dispatch]);
  const handleLastNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setField({field: `lastName`, value: event.currentTarget.value}));
  }, [dispatch]);
  const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setField({field: `email`, value: event.currentTarget.value}));
  }, [dispatch]);
  const handlePhoneChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setField({field: `phone`, value: event.currentTarget.value}));
  }, [dispatch]);
  const handleAgreeToTermsChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setField({field: `agreeToTerms`, value: event.currentTarget.checked}));
  }, [dispatch]);

  return {
    shouldFormAutocomplete,
    data,
    errors,
    handleReset,
    handleSubmit,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    handlePhoneChange,
    handleAgreeToTermsChange,
  }
}

import {useAppSelector} from "../../../redux/hooks";
import {useEffect} from "react";
import {kioskSyncService} from "../../../services/event/kiosk-sync/kiosk-sync-service";
import {getLeadCollectionIsAuthenticated} from "../../../redux/lead-collection/lead-collection.selectors";


export const useSyncConnectorLogic = () => {
  const isAuthenticated = useAppSelector(getLeadCollectionIsAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) {
      kioskSyncService.stopLeadSync();
      // return to exit
      return ;
    }

    kioskSyncService.startLeadSync();

    return () => kioskSyncService.stopLeadSync();
  }, [isAuthenticated]);

}

import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {initialState} from "./lead-collection.initialState";
import {setFieldReducer} from "./reducers/set-field";
import {resetFieldReducer} from "./reducers/reset-field";
import {
  setPostSubmitPrizeSpinResultsReducer,
  setSpinWheelShouldSpinReducer,
  submitFormReducer
} from "./reducers/submit-form";
import {resetFormReducer} from "./reducers/reset-form";
import {closeAllDisplayModalsReducer, setDisplayModalStatusReducer} from "./reducers/display-modal";
import {SystemCollectorType} from "../system/system.slice";
import {setKioskAuthenticationReducer} from "./reducers/set-kiosk-authentication";
import {setFormCollectorReducer} from "./reducers/set-form-collector";
import {
  setIsSpinWheelEnabledReducer,
  setSpinWheelResultsReducer,
  setSubmissionDetailsReducer
} from "./reducers/submit-details";
import type {SpinWheelItemValue} from "../../config/spin-wheel";
import {resetLeadDataReducer} from "./reducers/reset-lead-data";

export interface ILeadCollectionStoreValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  agreeToTerms: boolean;
}

export type LeadCollectionStoreField = keyof ILeadCollectionStoreValues;
export interface ILeadCollectionStoreFieldErrors extends Partial<Record<LeadCollectionStoreField, string>> {
}
export interface ILeadCollectionSubmissionSpinStore {
  value?: SpinWheelItemValue;
  isSpinAgain?: boolean;
  shouldSpin?: boolean;
}
export interface ILeadCollectionSubmissionStore {
  id: number;
  uuid: string;
  submittedAt: number;
  spin?: ILeadCollectionSubmissionSpinStore;
}

export interface ILeadCollectionDisplayStore {
  isTermsModalOpen?: boolean;
  isThankYouModalOpen?: boolean;
  isSpinWheelModalOpen?: boolean;
  isSpinWheelResultsModalOpen?: boolean;
}

export interface ILeadCollectionStore {
  isAuthenticated?: boolean;
  collector?: SystemCollectorType;
  eventId?: number;
  kioskId?: number;
  shouldFormAutocomplete?: boolean;
  isSpinWheelEnabled: boolean;
  values: ILeadCollectionStoreValues;
  errors?: ILeadCollectionStoreFieldErrors;
  submitDetails?: ILeadCollectionSubmissionStore;
  display?: ILeadCollectionDisplayStore;
}

export type LeadCollectionReducer<PayloadType = unknown> = (store: ILeadCollectionStore, action: PayloadAction<PayloadType>) => ILeadCollectionStore;



export const leadCollectionSlice = createSlice({
  name: `leadCollection`,
  initialState,
  reducers: {
    setKioskAuthentication: setKioskAuthenticationReducer,
    setFormCollector: setFormCollectorReducer,
    setIsSpinWheelEnabled: setIsSpinWheelEnabledReducer,
    setField: setFieldReducer,
    resetField: resetFieldReducer,
    submitForm: submitFormReducer,
    resetLeadData: resetLeadDataReducer,
    resetForm: resetFormReducer,
    setSubmissionDetails: setSubmissionDetailsReducer,
    setSpinWheelResults: setSpinWheelResultsReducer,
    setSpinWheelShouldSpin: setSpinWheelShouldSpinReducer,
    setPostSubmitPrizeSpinResults: setPostSubmitPrizeSpinResultsReducer,
    setDisplayModalStatus: setDisplayModalStatusReducer,
    closeAllDisplayModals: closeAllDisplayModalsReducer,
  },
});

export const {
  setFormCollector,
  setKioskAuthentication,
  setIsSpinWheelEnabled,
  setField,
  resetField,
  submitForm,
  resetForm,
  resetLeadData,
  setSubmissionDetails,
  setSpinWheelResults,
  setSpinWheelShouldSpin,
  setPostSubmitPrizeSpinResults,
  setDisplayModalStatus,
  closeAllDisplayModals,
} = leadCollectionSlice.actions;
export const leadCollectionReducer = leadCollectionSlice.reducer;

import {FunctionComponent} from "react";
import logoSrc from "./images/MIT45Logo.svg";
import and2xSrc from "./images/and.png";
import saving2xSrc from "./images/50off-boost-gummies@2x.png";
import spinToWin2xSrc from "./images/spin-to-win@2x.png";
import s from "./LeadCollectionHeader.module.scss";
import {useAppSelector} from '../../../redux/hooks';
import {getIsSpinWheelEnabled} from '../../../redux/lead-collection/lead-collection.selectors';

export const LeadCollectionHeader: FunctionComponent = () => {

  const isSpinWheelEnabled = useAppSelector(getIsSpinWheelEnabled)
  return (
    <header className={s.container}>
      <div className={s.topBar}>
        <img className={s.mit45Logo} src={logoSrc} alt={`MIT45 logo`}/>
      </div>
      <div className={s.info}>
        <img className={s.savingsImage} src={saving2xSrc} alt={`50 % off boost gummies image`}/>
        {isSpinWheelEnabled && (
          <>
            <div className={s.andWrapper}>
              <img className={s.andImage} src={and2xSrc} alt={`and`}/>
            </div>
            <img className={s.spinToWinImage} src={spinToWin2xSrc} alt={`spin to win image`}/>
          </>
        )}

      </div>
    </header>
  );
}

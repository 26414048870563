import {useDivRef} from "../../hooks/ref";
import {MutableRefObject, useLayoutEffect} from "react";
import {spinWheelService} from "../../../services/spin-wheel/spin-wheel-service";
import {useAppSelector} from "../../../redux/hooks";
import {getShouldWheelSpin} from "../../../redux/lead-collection/lead-collection.selectors";

export interface ISpinWheelContainerLogic {
  spinWheelRef: MutableRefObject<HTMLDivElement>;
}

export const useSpinWheelContainerLogic = (): ISpinWheelContainerLogic => {
  const spinWheelRef = useDivRef();
  const shouldSpin = useAppSelector(getShouldWheelSpin);

  useLayoutEffect(() => {
    spinWheelService.attachContainer(spinWheelRef.current);

    return () => spinWheelService.detachContainer();
  }, [spinWheelRef]);

  useLayoutEffect(() => {
    if (!shouldSpin) {
      // nothing to do, return to exit
      return ;
    }
    const spinTimeout = setTimeout(
      () => {
        spinWheelService.spin();
      },
      // spin after 1.5 sec
      1500
    );

    return () => clearTimeout(spinTimeout);
  }, [shouldSpin]);



  return {
    spinWheelRef,
  }
}

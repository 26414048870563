import {initialState} from "../lead-collection.initialState";
import {type LeadCollectionReducer} from "../lead-collection.slice";
import {isDefined} from "is-type-util";

export const resetFormReducer: LeadCollectionReducer<undefined> = (store) => {

  const display = isDefined(initialState.display)
    ? {...initialState.display}
    : undefined;
  const submitDetails = isDefined(initialState.submitDetails)
    ? {...initialState.submitDetails}
    : undefined;
  const errors = isDefined(initialState.submitDetails)
    ? {...initialState.errors}
    : undefined;

  return {
    ...store,
    display,
    submitDetails,
    errors,
    values: {
      ...initialState.values,
    },
  };
};

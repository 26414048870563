import {EventDatabase, eventDatabaseInstance} from "../../database/event-database";
import {Event} from "../../database/models/Event";


export class EventRepository {
  constructor(
    private database: EventDatabase = eventDatabaseInstance
  ) {}

  public async getForId(id: number): Promise<Event | undefined>
  {
    return this.database
      .events
      .get(id);
  }

  public async getAll(): Promise<Event[]>
  {
    const events = await this.database
      .events
      .orderBy('id')
      .toArray();

    return events ?? [];
  }

  public async create(id: number, name: string): Promise<Event>
  {
    const newEvent: Event = {
      id,
      name,
    };

    await this.database
      .events
      .add(newEvent, id);

    return newEvent;
  }

  public async delete(id: number): Promise<boolean>
  {
    try {
      this.database
        .events
        .delete(id);
      return true;
    } catch (_error) {
      // unable to update status
      return false;
    }
  }
}

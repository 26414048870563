import {FunctionComponent} from "react";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CallToActionOutlinedIcon from "@mui/icons-material/CallToActionOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import {SyncInfoModal} from "../modals/sync-info/SyncInfoModal";
import {useKioskSpeedDialLogic} from "./KioskSpeedDial.logic";
import {SettingsModal} from "../modals/settings/SettingsModal";
import {ConnectionStatusIcon} from "../icons/connection-status-icon/ConnectionStatusIcon";
import {SyncStatusIcon} from "../icons/sync-status-icon/SyncStatusIcon";
import {buildVersion} from "../../config/environment";
import {SpinWheelModal} from "../modals/spin-wheel/SpinWheelModal";
import {ThankYouModal} from "../modals/thank-you/ThankYouModal";
import {SyncLeadsModal} from "../modals/sync-leads/SyncLeadsModal";
import {manageLeadsFeatureFlag} from "../../config/feature-flags";


export const KioskSpeedDial: FunctionComponent = () => {
  const {handleOpenSyncInfoModal, handleOpenSettingsModal} = useKioskSpeedDialLogic();
  return (
    <>
      <SpeedDial
        ariaLabel="Kiosk SpeedDial"
        sx={{position: 'fixed', bottom: 16, right: 16,}}
        FabProps={{size: `medium`, color: undefined}}
        icon={<SpeedDialIcon
          icon={<CallToActionOutlinedIcon color={`action`}/>}
          openIcon={<CancelPresentationOutlinedIcon/>}/>}
      >
        <SpeedDialAction
          icon={<SettingsIcon/>}
          tooltipTitle={`Settings`}
          onClick={handleOpenSettingsModal}
        />
        <SpeedDialAction
          icon={<SyncStatusIcon />}
          tooltipTitle={`Sync`}
          onClick={handleOpenSyncInfoModal}
        />
        <SpeedDialAction
          icon={<InfoIcon />}
          tooltipTitle={`${buildVersion}`}
          tooltipOpen
        />
        <SpeedDialAction
          icon={<ConnectionStatusIcon />}
          tooltipTitle={`Connection`}
        />
      </SpeedDial>
      <ThankYouModal />
      <SpinWheelModal />
      <SyncInfoModal />
      {manageLeadsFeatureFlag && <SyncLeadsModal />}
      <SettingsModal />
    </>
  );
}

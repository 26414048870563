import {SystemCollectorType} from "../redux/system/system.slice";

export interface ILeadCollectionAutoComplete extends Record<SystemCollectorType, boolean>{}

export const leadCollectionAutoComplete: ILeadCollectionAutoComplete = {
  kiosk: false,
  "affiliate-kiosk": false,
  "qr-code": true,
  "affiliate-qr-code": true,
};
export const leadCollectionConfig = {
  autoComplete: leadCollectionAutoComplete,
}

import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {getIsLeadCollectionSpinWheelModalOpen} from "../../../redux/lead-collection/lead-collection.selectors";
import {useCallback} from "react";
import {resetLeadData} from "../../../redux/lead-collection/lead-collection.slice";

export interface ISpinWheelModalLogic {
  isOpen: boolean;
  handleClose: () => void;
}

export const useSpinWheelModalLogic = (): ISpinWheelModalLogic => {

  const isOpen = useAppSelector(getIsLeadCollectionSpinWheelModalOpen);

  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(resetLeadData());
  }, [dispatch]);

  return {
    isOpen,
    handleClose,
  };
}

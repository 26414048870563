import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
  getDoesWheelHaveSpinResults,
  getShouldWheelSpin,
  getWheelSpinResults
} from "../../../redux/lead-collection/lead-collection.selectors";
import {useLayoutEffect} from "react";
import {getSpinWheelItemValueMessage, SpinWheelItemValue} from "../../../config/spin-wheel";
import {setSpinWheelShouldSpin} from "../../../redux/lead-collection/lead-collection.slice";
import {isDefined} from "is-type-util";

export interface ISpinWheelResultsLogic {
  wheelResultValue?: SpinWheelItemValue;
  hasWheelResult: boolean;
  wheelResultMessage?: string;
  doesWheelResultWinItem: boolean;
}

export const useSpinWheelResultsLogic = (): ISpinWheelResultsLogic => {

  const wheelResultValue = useAppSelector(getWheelSpinResults);
  const hasWheelResult = useAppSelector(getDoesWheelHaveSpinResults);
  const shouldSpin = useAppSelector(getShouldWheelSpin);

  const doesWheelResultWinItem: boolean = (
    isDefined(wheelResultValue)
    && !(
      wheelResultValue === SpinWheelItemValue.SpinAgain
      || wheelResultValue === SpinWheelItemValue.None
    )
  );
  const wheelResultMessage = hasWheelResult ?
    getSpinWheelItemValueMessage(wheelResultValue!) :
    undefined;

  const dispatch = useAppDispatch();
  useLayoutEffect(() => {
    if (!hasWheelResult || shouldSpin) {
      // nothing to show or needs to spin again, return to exit
      return;
    }

    if (wheelResultValue !== SpinWheelItemValue.SpinAgain) {
      // not spin again, return to exit
      return;
    }

    const timeout = setTimeout(() => {
      dispatch(setSpinWheelShouldSpin());
    }, 2000);

    return () => clearTimeout(timeout);

  }, [hasWheelResult, wheelResultValue, shouldSpin, dispatch]);

  return {
    wheelResultValue,
    hasWheelResult,
    wheelResultMessage,
    doesWheelResultWinItem,
  };
}

import {IPulseClientProvider} from "./pulse-client-provider";
import axios, {AxiosInstance, } from "axios";
import {systemConfig} from "../../../config/system";


export class EventKioskProvider implements IPulseClientProvider {

  private readonly instance: AxiosInstance;
  constructor(kioskToken: string) {
    this.instance = axios.create({
      baseURL: systemConfig.baseUrl,
      // timeout: 1000,
      headers: {
        Accept: "application/json",
        Authorization: 'Bearer ' + kioskToken
      },
    });

  }


  public getInstance(): AxiosInstance
  {
    return this.instance;
  }
}


export const manageLeadsFeatureFlag: boolean = false;
export const quickSyncFeatureFlag: boolean = false;
export const productMonitoringFeatureFlag: boolean = false;

export const featureFlags = {
  productMonitoring: productMonitoringFeatureFlag,
  manageLeads: manageLeadsFeatureFlag,
  quickSync: quickSyncFeatureFlag,
};

import s from "./KioskPortal.module.scss"
import {LeadCollectionForm} from "../lead-collection/form/LeadCollectionForm";
import {useKioskPortalLogic} from "./KioskPortal.logic";
import {LeadCollectionHeader} from "../lead-collection/header/LeadCollectionHeader";
import {KioskSpeedDial} from "../kiosk-speed-dial/KioskSpeedDial";

export const KioskPortal = () => {

  const {isKioskReady} = useKioskPortalLogic();

  if (!isKioskReady) {
    return (
      <div className={s.setupContainer}>
        <KioskSpeedDial />
        Kiosk not ready
      </div>
    )
  }

  return (
    <div>
      <KioskSpeedDial />
      <LeadCollectionHeader />
      <LeadCollectionForm collector={"kiosk"} />
    </div>
  );
}

import {FunctionComponent} from "react";
import {useSpinWheelResultsLogic} from "./SpinWheelResults.logic";
import s from "./SpinWheelResults.module.scss";
import {SpinWheelItemValue} from "../../../config/spin-wheel";


export const SpinWheelResults: FunctionComponent = () => {
  const {hasWheelResult, doesWheelResultWinItem, wheelResultMessage, wheelResultValue} = useSpinWheelResultsLogic();

  if (!hasWheelResult){
    // nothing to show
    return null;
  }

  if (doesWheelResultWinItem) {
    return (
      <div className={s.winContainer}>
        <h2>Congratulations, {wheelResultMessage}</h2>
        Please show booth staff to collect your prize.
      </div>
    );
  }

  if (wheelResultValue === SpinWheelItemValue.None) {
    return (
      <h2 className={s.sorryContainer}>
        Sorry, {wheelResultMessage}
      </h2>
    );
  }
  return (
    <div className={s.infoContainer}>
      {wheelResultMessage}
    </div>
  )
}

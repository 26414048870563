import {SystemCollectorType} from "../../../redux/system/system.slice";
import {KioskLeadRepository} from "../../../repositories/events/kiosk-lead-repository";
import {ReduxStateService} from "../../state/redux-state";
import {
  getLeadCollectionEventId,
  getLeadCollectionKioskId
} from "../../../redux/lead-collection/lead-collection.selectors";
import {v4} from "uuid";
import {setDisplayModalStatus, setSubmissionDetails} from "../../../redux/lead-collection/lead-collection.slice";

export interface ICollectedLead {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
}
export class LeadCollectionService {
  constructor(
    private kioskLeadRepository: KioskLeadRepository = new KioskLeadRepository(),
  ) {
  }

  private async collectKioskLead(lead: ICollectedLead): Promise<void>
  {
    // active event
    const eventId = ReduxStateService.getSelector(getLeadCollectionEventId)!;
    // active kiosk
    const kioskId = ReduxStateService.getSelector(getLeadCollectionKioskId)!;

    const submittedAt = Math.floor(Date.now() / 1000);
    const uuid = v4();
    const id = await this.kioskLeadRepository
      .create(
        eventId,
        kioskId,
        {
          ...lead,
          submittedAt,
          uuid,
        }
      );

    ReduxStateService.dispatch(setSubmissionDetails({id, uuid, submittedAt}));
    ReduxStateService.dispatch(setDisplayModalStatus({ modal: "isThankYouModalOpen", status: true}))

  }

  private async collectQRCodeLead(lead: ICollectedLead): Promise<void>
  {

  }

  private async collectAffiliateKioskLead(lead: ICollectedLead): Promise<void>
  {

  }

  private async collectAffiliateQRCodeLead(lead: ICollectedLead): Promise<void>
  {

  }

  public async collectLead(collector: SystemCollectorType, lead: ICollectedLead): Promise<void>
  {
    switch (collector){
      case "kiosk":
        return this.collectKioskLead(lead);
      case "qr-code":
        return this.collectQRCodeLead(lead);
      case "affiliate-kiosk":
        return this.collectAffiliateKioskLead(lead);
      case "affiliate-qr-code":
        return this.collectAffiliateQRCodeLead(lead);
    }
  }

}

export const leadCollectionService = new LeadCollectionService();

import {localStorageService} from "../storage/local-storage";
import {Kiosk} from "../../database/models/Kiosk";
import {isUndefined} from "is-type-util";
import {KioskRepository} from "../../repositories/events/kiosk-repository";

const activeKioskStorageKey = `ACTIVE_KIOSK`;

export class ActiveKioskService {

  constructor(
    private kioskRepository: KioskRepository = new KioskRepository(),
  ) {}

  public setActiveKioskId(kioskId: number)
  {
    localStorageService.setJsonItem<number>(activeKioskStorageKey, kioskId);
  }
  public getKioskId(): number | undefined
  {
    return localStorageService.getJsonItem<number>(activeKioskStorageKey);
  }

  public clearActiveKioskId(): void
  {
    localStorageService.removeItem(activeKioskStorageKey)
  }

  public async getKiosk(): Promise<Kiosk | undefined>
  {
    const activeKioskId = this.getKioskId();
    if (isUndefined(activeKioskId)) {
      return undefined;
    }
    return this.kioskRepository.getForId(activeKioskId);
  }

}

export const activeKioskService = new ActiveKioskService();

import {useAppDispatch} from "../../redux/hooks";
import {useCallback} from "react";
import {activateModal} from "../../redux/display/display.slice";

export interface IKioskSpeedDialLogic {
  handleOpenSyncInfoModal: () => void;
  handleOpenSettingsModal: () => void;
}

export const useKioskSpeedDialLogic = () : IKioskSpeedDialLogic => {

  const dispatch = useAppDispatch();

  const handleOpenSyncInfoModal = useCallback(() => {
    dispatch(activateModal(`sync-info`))
  }, [dispatch]);

  const handleOpenSettingsModal = useCallback(() => {
    dispatch(activateModal(`settings`))
  }, [dispatch]);


  return {
    handleOpenSyncInfoModal,
    handleOpenSettingsModal,
  };
}

import {FunctionComponent, PropsWithChildren} from "react";
import {Provider} from "react-redux";
import {store} from "./store";
import PropTypes from "prop-types";

export const ReduxProvider: FunctionComponent<PropsWithChildren> = ({children}) => {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
}

ReduxProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

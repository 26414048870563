import {useAppSelector} from "../../../redux/hooks";
import {getIsConnected} from "../../../redux/connection/connection.selectors";
import {getHasSyncPending, getHasSyncSending, getSyncPendingStat} from "../../../redux/sync/sync.selectors";

export interface ISyncStatusIconLogic {
  isConnected: boolean;
  hasAvailableToSync: boolean;
  pending: number;
}

export const useSyncStatusIconLogic = (): ISyncStatusIconLogic => {
  const isConnected = useAppSelector(getIsConnected);
  const hasPending = useAppSelector(getHasSyncPending);
  const hasSending = useAppSelector(getHasSyncSending);
  const pending = useAppSelector(getSyncPendingStat);

  const hasAvailableToSync = (hasPending || hasSending);

  return {
    isConnected,
    hasAvailableToSync,
    pending,
  };
}

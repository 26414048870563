import {EventDatabase, eventDatabaseInstance} from "../../database/event-database";
import {Kiosk} from "../../database/models/Kiosk";

export class KioskRepository {
  constructor(
    private database: EventDatabase = eventDatabaseInstance
  ) {}


  public async getForId(id: number)
  {
    return this.database
      .kiosks
      .get(id);
  }

  public async getAll(): Promise<Kiosk[]>
  {
    return this.database
      .kiosks
      .orderBy('eventId')
      .sortBy('id');
  }

  public async getAllForEvent(eventId: number)
  {
    return this.database
      .kiosks
      .where({
        eventId,
      })
      .toArray();
  }

  public async create(eventId: number, id: number, token: string)
  {
    const newKiosk: Kiosk = {
      id,
      eventId,
      token,
    };

    await this.database
      .kiosks
      .add(newKiosk, id);

    return newKiosk;
  }

  public async delete(id: number): Promise<boolean>
  {

    try {
      this.database
        .kiosks
        .delete(id);
      return true;
    } catch (_error) {
      // unable to update status
      return false;
    }
  }
}

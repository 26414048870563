import {IPulseClientProvider} from "./pulse-client-provider";
import axios, {AxiosInstance,} from "axios";
import {systemConfig} from "../../../config/system";


export class QuickTimeoutProvider implements IPulseClientProvider {

  private readonly instance: AxiosInstance;
  constructor() {
    this.instance = axios.create({
      baseURL: systemConfig.baseUrl,
      // 2 sec ping timeout
      // timeout: 2000,
      headers: {
        Accept: "application/json",
      },
    });
  }

  public getInstance(): AxiosInstance
  {
    return this.instance;
  }
}

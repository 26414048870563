import {AbstractPulseClient} from "./abstract-client";
import {AxiosResponse} from "axios";
import {QuickTimeoutProvider} from "./providers/quick-timeout-provider";


export class PingClient extends AbstractPulseClient {

  constructor() {
    super(`/ping`, new QuickTimeoutProvider());
  }

  public async ping()
  {
    try {
      const {data, status} = await super.getList<never, {status: boolean}>();

      return (
        status === 200
        && !!data?.status
      );
    } catch (_e) {
      return false;
    }
  }

  async getOne<ResponseType = unknown>(identifier: never): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }

  async getList<RequestFilters = Record<string, unknown>, ResponseType = unknown>(filters?: RequestFilters): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }

  async deleteOne<ResponseType = unknown>(identifier: never): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }

  async updateOne<ResponseType = unknown>(identifier: never, data: unknown): Promise<AxiosResponse<ResponseType>> {
    throw new Error(`Not Implemented`);
  }

}


import {FunctionComponent} from "react";
import s from "./SettingsForm.module.scss";
import {useSettingsFormLogic} from "./SettingsForm.logic";
import Button from "@mui/material/Button";


export const SettingsForm: FunctionComponent = () => {
  const {
    data,
    handleSubmit,
    handleReset,
    handleEventChange,
    handleKioskChange,
    handleTokenChange,
  } = useSettingsFormLogic();

  return (
    <div className={s.container}>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div className={s.inputGroup}>
          <label className={s.inputLabel}> Event:
            <input value={data.event} className={s.inputText} onChange={handleEventChange} type={"number"} required />
          </label>
        </div>
        <div className={s.inputGroup}>
          <label className={s.inputLabel}> Kiosk:
            <input value={data.kiosk} className={s.inputText} onChange={handleKioskChange} type={"number"} required />
          </label>
        </div>
        <div className={s.inputGroup}>
          <label className={s.inputLabel}> Token:
            <input value={data.token} className={s.inputText} onChange={handleTokenChange} type={"text"}/>
          </label>
        </div>
        <div className={s.inputActionGroup}>
          <Button variant="contained" color="secondary" type={"reset"}>Reset</Button>
          <Button variant="contained" color="primary" type={`submit`}>Submit</Button>
        </div>

      </form>
    </div>
  );
}

import {LeadCollectionReducer} from "../lead-collection.slice";
import {initialState} from "../lead-collection.initialState";

export const resetLeadDataReducer: LeadCollectionReducer<undefined> = (store) => {
  return {
    ...store,
    values: {
      ...initialState.values,
    },
    submitDetails: undefined,
    errors: undefined,
    display: undefined,
  }
}

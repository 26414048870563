import React from "react";
import type {FunctionComponent, PropsWithChildren} from "react";
import s from './Modal.module.scss'
import {isDefined} from "is-type-util";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


export interface IModalProps {
  isOpen?: boolean;
  title: string;
  handleClose?: () => void | Promise<void>;
  actionText?: string;
  handleAction?: () => void | Promise<void>;
  fullScreen?: boolean;
}

const Modal: FunctionComponent<PropsWithChildren<IModalProps>> = ({isOpen, title, handleClose, actionText, handleAction, fullScreen, children}) => {


  if (!isOpen) {
    return null;
  }
  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      fullWidth={true}
      maxWidth={'md'}
      fullScreen={fullScreen}
      className={s.container}
    >
      <DialogTitle className={s.title} sx={{ m: 0, p: 2 }}>
        {title}
      </DialogTitle>
      {isDefined(handleClose) && <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
      >
          <CloseIcon />
      </IconButton>}
      <DialogContent dividers className={s.content}>
        {children}
      </DialogContent>
      <DialogActions className={s.actions}>
        {isDefined(handleClose) && <Button color="secondary" variant="outlined" onClick={handleClose}>
            Close
        </Button>}
        {(isDefined(handleAction) && isDefined(actionText)) && <Button onClick={handleAction} variant="contained">
          {actionText}
        </Button>}

      </DialogActions>
    </Dialog>
  );
};

export default Modal;

import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {getIsSettingsModalActive} from "../../../redux/display/display.selectors";
import {useCallback} from "react";
import {deactivateModal} from "../../../redux/display/display.slice";

export interface ISettingsModalLogic {
  isOpen: boolean;
  handleClose: () => void;
}
export const useSettingsModalLogic = (): ISettingsModalLogic => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(getIsSettingsModalActive);
  const handleClose = useCallback(() => {
    dispatch(deactivateModal(`settings`))
  }, [dispatch]);

  return {
    isOpen,
    handleClose,
  };
}

import {FunctionComponent} from "react";
import {useSyncStatusIconLogic} from "./SyncStatusIcon.logic";
import PendingIcon from '@mui/icons-material/Pending';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import {Badge} from "@mui/material";

export const SyncStatusIcon: FunctionComponent = () => {
  const {isConnected, hasAvailableToSync, pending} = useSyncStatusIconLogic();

  if (!isConnected) {
    // not connected
    return <PendingIcon />;
  }

  if (!hasAvailableToSync) {
    return <CloudDoneIcon />;
  }

  if (!pending) {
    // none currently pending
    return <CloudSyncIcon />;
  }

  return (
    <Badge color={`success`} badgeContent={pending} max={999} >
      <CloudSyncIcon />
    </Badge>
  );
}


// TODO: use env for url to support dev
const host = `https://pulse.mit45.com`;
const basePath = `/api`;
const baseUrl = `${host}${basePath}`;

export const systemConfig = {
  host,
  basePath,
  baseUrl,
};

import {useAppSelector} from "../../../redux/hooks";
import {getIsConnected} from "../../../redux/connection/connection.selectors";

export interface IConnectionStatusIconLogic {
  isConnected: boolean;
}

export const useConnectionStatusIconLogic = (): IConnectionStatusIconLogic => {

  const isConnected = useAppSelector(getIsConnected);

  return {
    isConnected
  }
}

import {KioskRepository} from "../../../repositories/events/kiosk-repository";
import {KioskLeadRepository} from "../../../repositories/events/kiosk-lead-repository";
import {isDefined, isUndefined} from "is-type-util";
import {EventKioskLeadsClient} from "../../../clients/pulse/event-kiosk-leads-client";
import {
  connectionDetectionService,
} from "../../connection/connection-detection-service";
import {productMonitoring} from "../../logging/product-monitoring";
import {quickSyncFeatureFlag} from "../../../config/feature-flags";


const leadSyncAttemptSeconds: number = (quickSyncFeatureFlag ? 3 : 10);
const leadSyncAttemptInterval: number = leadSyncAttemptSeconds * 1000;

class KioskSyncService {

  private activeKioskId?: number;
  private activeKioskClient?: EventKioskLeadsClient;


  private isSyncActive: boolean = false;
  private leadSyncInterval?: NodeJS.Timeout;

  constructor(
    private kioskRepository: KioskRepository = new KioskRepository(),
    private leadRepository: KioskLeadRepository = new KioskLeadRepository(),
  ) {}

  private async getKioskClient(kioskId: number): Promise<EventKioskLeadsClient>
  {
    if (this.activeKioskId === kioskId && isDefined(this.activeKioskClient)) {
      return this.activeKioskClient;
    }

    const kiosk = await this.kioskRepository
      .getForId(kioskId);

    if (isUndefined(kiosk)) {
      throw new Error(`unable to get provider for`)
    }

    this.activeKioskId = kioskId;
    this.activeKioskClient = new EventKioskLeadsClient(kiosk.eventId, kiosk.id, kiosk.token);

    return this.activeKioskClient;
  }

  private async attemptLeadSync(): Promise<void>
  {
    const leadToSync = await this.leadRepository
      .getNextPending();

    if (isUndefined(leadToSync)) {
      // no current pending leads to sync
      // return to exit
      return ;
    }

    await this.leadRepository
      .updateStatus(leadToSync.id!, "sending");

    const client = await this.getKioskClient(leadToSync.kioskId);

    try {

      const createResponse = await client.createOne<{data: { id: number; uuid: string, submitted_at: string; }; }>({
        uuid: leadToSync.uuid,
        first_name: leadToSync.firstName,
        last_name: leadToSync.lastName,
        email: leadToSync.email,
        phone: leadToSync.phone,
        submitted_at: leadToSync.submittedAt,
      });


      const isRequestSuccessful = (
        (createResponse.status === 201 || createResponse.status === 200)
        && createResponse.data.data.uuid === leadToSync.uuid
      );

      productMonitoring.track(`kiosk:lead:sync:complete`, {
        isRequestSuccessful,
        status: createResponse.status,
        leadUuid: leadToSync.uuid,
        responseUuid: createResponse.data.data.uuid,
        uuidMatch: (leadToSync.uuid === createResponse.data.data.uuid)
      })

      if (!isRequestSuccessful) {
        await this.leadRepository
          .addLeadSyncFailedStatus(leadToSync.id!, {
            isRequestSuccessful,
            request: {
              uuid: leadToSync.uuid,
            },
            response: {
              status: createResponse.status,
              data: createResponse.data.data,
            }
          });
      } else {
        await this.leadRepository
          .updateStatus(leadToSync.id!, "complete");
      }

    } catch (requestError) {

      productMonitoring.captureException(requestError as Error)

      await this.leadRepository
        .updateStatus(leadToSync.id!, "failed");
    }

    this.isSyncActive = false;
  }

  private async handleScheduledLeadSync(): Promise<void>
  {

    const shouldAttemptToSyncLead = (
      // no current other syncs active
      !this.isSyncActive
      // is currently online
      && connectionDetectionService.online
    );
    if (!shouldAttemptToSyncLead) {
      // shouldn't attempt to sync leads
      // return to exist
      return ;
    }

    // update flag to show sync starting
    this.isSyncActive = true;

    try {
      await this.attemptLeadSync();
      if (quickSyncFeatureFlag) {
        // if feature flag for quick sync enabled, attempt 2nd lead sync
        await this.attemptLeadSync();
      }
    } catch (attemptSyncError) {
      console.error(`lead sync error`, attemptSyncError);
    }
    // update flag to reflect sync completed
    this.isSyncActive = false;
  }

  public stopLeadSync(): void
  {
    if (isDefined(this.leadSyncInterval)) {
      clearInterval(this.leadSyncInterval);
    }
  }

  public startLeadSync(): void
  {
    // stop sync interval if already in process
    this.stopLeadSync();

    void this.handleScheduledLeadSync();
    this.leadSyncInterval = setInterval(
      () => {
        void this.handleScheduledLeadSync();
      },
      leadSyncAttemptInterval
    );
  }

  public async queueFailedLeadsForSync(): Promise<void>
  {
    const pendingTotal = await this.leadRepository.getFailedCount();
    void await this.leadRepository
      .markSendingForRetry();
    void await this.leadRepository
      .markFailedForRetry();
  }

}

export const kioskSyncService = new KioskSyncService();


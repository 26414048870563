import {isUndefined} from "is-type-util";


export interface IWeightedPrize {
  excludeFromDraw?: boolean;
  wheelIndex: number;
  weight: number;
  value?: number;
}

interface IWeightedItemDetails {
  wheelIndex: number;
  rangeMin: number;
  rangeMax: number;
}

export class RandomWeightedPrize {
  private totalWeight: number = 0;
  private items?: IWeightedItemDetails[];


  private addItem(prizeItem: IWeightedPrize)
  {
    if (isUndefined(this.items)){
      this.items = [
        {
          wheelIndex: prizeItem.wheelIndex,
          rangeMin: 0,
          rangeMax: (prizeItem.weight - 1),
        },
      ];
      // return to exit
      return;
    }

    if (prizeItem.excludeFromDraw) {
      // nothing shouldn't be in results
      // return to exit
      return;
    }

    const previousMax = Math.max(...this.items.map(({rangeMax}) => rangeMax));
    const rangeMin = previousMax + 1;
    const rangeMax = previousMax + prizeItem.weight;
    this.items.push({
      wheelIndex: prizeItem.wheelIndex,
      rangeMin,
      rangeMax,
    });

  }
  public setItems(prizeItems: IWeightedPrize[])
  {
    this.totalWeight = prizeItems
      .filter(({excludeFromDraw}) => !(excludeFromDraw ?? false))
      .map(({weight}) => weight)
      .reduce((previousValue, currentValue) => previousValue + currentValue);

    prizeItems.forEach((prizeItem) => this.addItem(prizeItem));
  }

  public getRandomWheelIndex(): number | undefined
  {
    const randomValue = Math.floor(Math.random() * this.totalWeight);
    const randomItem = this.items
      ?.find(({rangeMin, rangeMax}) => rangeMin <= randomValue && rangeMax >= randomValue);

    return randomItem?.wheelIndex;
  }
}

export const randomWeightedPrize = new RandomWeightedPrize();

import {FunctionComponent} from "react";
import {useSettingsCurrentLogic} from "./SettingsCurrent.logic";
import s from "./SettingsCurrent.module.scss"

export interface ISettingsCurrent {

}

export const SettingsCurrent: FunctionComponent = () => {
  const {isAuthenticated, eventId, kioskId} = useSettingsCurrentLogic();
  return (
    <div className={s.container}>
      <h2 className={s.status}>Current {isAuthenticated && `[authenticated]`}</h2>
      <div className={s.config}>Event: {eventId ?? `--`}</div>
      <div className={s.config}>Kiosk: {kioskId ?? `--`}</div>
    </div>
  );
}

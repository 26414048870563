import {createSlice, type PayloadAction} from "@reduxjs/toolkit";
import {isUndefined} from "is-type-util";


export interface ISystemWheelNoPrize {
  id: number;
  type: 'no-prize';
  label: string;
  image?: string;
  chanceOfSelecting: number;
}
export interface ISystemWheelSpinAgain {
  id: number;
  type: 'spin-again';
  label: string;
  image?: string;
  chanceOfSelecting: number;
}
export interface ISystemWheelSample {
  id: number;
  type: 'sample';
  item: string;
  label: string;
  image?: string;
  chanceOfSelecting: number;
}
export interface ISystemWheelSwag {
  id: number;
  type: 'swag';
  item: string;
  label: string;
  image?: string;
  chanceOfSelecting: number;
}
export interface ISystemWheelPrize {
  id: number;
  type: 'prize';
  item: string;
  label: string;
  image?: string;
  chanceOfSelecting: number;
}

export type SystemWheelItem = ISystemWheelNoPrize |
  ISystemWheelSpinAgain |
  ISystemWheelSample |
  ISystemWheelSwag |
  ISystemWheelPrize;

export interface ISystemPrizeWheelStore {
  isActive?: boolean;
  result?: SystemWheelItem;
  items: SystemWheelItem[];
}
export interface ISystemEventStore {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
}

export type SystemCollectorType = `kiosk` |
  `qr-code` |
  `affiliate-kiosk` |
  `affiliate-qr-code`;
export interface ISystemInstanceStore {
  id: number;
  type: SystemCollectorType;
  name?: string;
}

export interface ISystemStore {
  event?: ISystemEventStore;
  instance?: ISystemInstanceStore;
  wheel?: ISystemPrizeWheelStore;
}

export const initialState: ISystemStore = {

};

export const systemSlice = createSlice({
  name: `system`,
  initialState,
  reducers: {
    setEvent: (store, action: PayloadAction<ISystemEventStore>): ISystemStore => {
      const event = action.payload;
      return {
        ...store,
        event,
      };
    },
    setInstance: (store, action: PayloadAction<[ISystemInstanceStore, Omit<ISystemPrizeWheelStore, 'result'> | undefined]>) => {
      const [instance, wheel] = action.payload;

      return {
        ...store,
        instance,
        wheel,
      };
    },
    setWheelResult: (store, action: PayloadAction<number>) => {
      const resultId = action.payload;
      if (isUndefined(store.wheel)){
        // no items to select from
        return store;
      }

      const result = store.wheel
        .items
        .find(({id}) => resultId === id);

      return {
        ...store,
        wheel: {
          ...store.wheel,
          result,
        },
      };
    },
    reset: (store) => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  setEvent,
  setInstance,
  setWheelResult,
  reset,
} = systemSlice.actions;
export const systemReducer = systemSlice.reducer;

import type {ILeadCollectionStore} from "./lead-collection.slice";


export const initialState: ILeadCollectionStore = {
  isSpinWheelEnabled: true,
  values: {
    firstName: ``,
    lastName: ``,
    email: ``,
    phone: ``,
    agreeToTerms: false,
  }
};

import {FunctionComponent} from "react";
import {useThankYouModalLogic} from "./ThankYouModal.logic";
import Modal from "../modal/Modal";


export const ThankYouModal: FunctionComponent = () => {
  const {isOpen, handleClose, handleAction, actionLabel, hasSpinWheel} = useThankYouModalLogic()
  return (

    <Modal
      title={`Thank you`}
      isOpen={isOpen}
      handleClose={handleClose}
      actionText={actionLabel}
      handleAction={handleAction}
    >
      Thank you!  You should receive an email soon with a code for 50% off your next purchase from MIT45.
      {hasSpinWheel && <div>
          You now can spin our prize wheel for a chance to win free product and apparel.
      </div>}
    </Modal>
  );
}

import {AppState} from "../store";
import {IConnectionStore} from "./connection.slice";

const getConnectionStore = (store: AppState): IConnectionStore =>
  store.connection;

export const getBrowserConnection = (store: AppState): boolean =>
  getConnectionStore(store).browserStatus ?? false;
export const getServerPingConnection = (store: AppState): boolean =>
  getConnectionStore(store).serverPingStatus ?? false;

export const getIsConnected = (store: AppState): boolean =>
  getBrowserConnection(store) && getServerPingConnection(store);

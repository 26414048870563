import {ChangeEvent, FunctionComponent} from "react";
import s from "./FormInputItem.module.scss";

export interface IFormInputItemProps {
    title: string;
    inputType?: "text" | "email" | "phone";
    inputPlaceholder?: string;
    isRequired?: boolean;
    shouldAutocomplete?: boolean;
    autocompleteType?: "given-name" | "family-name" | "email" | "tel";
    value: string;
    errorMessage?: string;
    onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FormInputItem: FunctionComponent<IFormInputItemProps> = ({title, inputType, inputPlaceholder, isRequired, shouldAutocomplete, autocompleteType, value, onChangeHandler, errorMessage}) => {
  return (
    <div className={s.detail}>
      <label className={s.labelWrapper}>
        <div className={s.label}>{title}{isRequired && `*`}</div>
        <div className={s.inputWrapper}>
          <input
            type={inputType}
            className={s.input}
            placeholder={inputPlaceholder}
            required={isRequired}
            autoComplete={shouldAutocomplete ? autocompleteType : `off`}
            value={value}
            onChange={onChangeHandler}
          />
        </div>
      </label>
    </div>
  );
}

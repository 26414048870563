import {PingClient} from "../../clients/pulse/ping-client";
import {isDefined} from "is-type-util";
import {ReduxStateService} from "../state/redux-state";
import {setServerPingStatus, setBrowserStatus} from "../../redux/connection/connection.slice";

// pull server every 30 seconds
const serverPollingInterval = 10 * 1000;

class ConnectionDetectionService {
  private isWindowOnline?: boolean;
  private canPingServer?: boolean;

  private serverCheckInterval?: NodeJS.Timeout;

  constructor(
    private pingClient: PingClient = new PingClient(),
  ) {
    // check if window online and start pulling server
    // this.onWindowConnectionChange();
  }
  public get online(): boolean
  {
    return (
      !!this.isWindowOnline
      && !!this.canPingServer
    );
  }

  private async sendServerPing()
  {
    const updatedCanPingServer= await this.pingClient
      .ping();

    if (updatedCanPingServer !== this.canPingServer) {
      ReduxStateService.dispatch(setServerPingStatus(updatedCanPingServer));
      this.canPingServer = updatedCanPingServer;
    }
  }

  private stopServerPolling(): void
  {
    if (isDefined(this.serverCheckInterval)) {
      clearInterval(this.serverCheckInterval);
    }
  }

  private startServerPolling(): void
  {
    // stop polling if already running

    this.serverCheckInterval = setInterval(() => {
      void this.sendServerPing();
    }, serverPollingInterval)
  }

  public onWindowConnectionChange(inOnline?: boolean): void
  {
    const newIsWindowOnline = inOnline ?? window.navigator.onLine;

    if (newIsWindowOnline !== this.isWindowOnline){
      ReduxStateService.dispatch(setBrowserStatus(newIsWindowOnline));
      this.isWindowOnline = newIsWindowOnline;
    }


    if (this.isWindowOnline) {
      // online, start checking server ping
      this.startServerPolling();
    } else {
      // offline, stop checking server ping
      this.stopServerPolling();
    }
  }

}

export const connectionDetectionService = new ConnectionDetectionService()

window.addEventListener(`online`, () => connectionDetectionService.onWindowConnectionChange(true))
window.addEventListener(`offline`, () => connectionDetectionService.onWindowConnectionChange(true))


import {type EventDatabase, eventDatabaseInstance} from "../../database/event-database";
import {Lead, LeadStatus, LeadSyncError} from "../../database/models/Lead";
import {isUndefined} from "is-type-util";


export class KioskLeadRepository {
  constructor(
    private database: EventDatabase = eventDatabaseInstance
  ) {}

  public async getById(id: number): Promise<Lead | undefined>
  {
    return this.database
      .leads
      .get(id);
  }

  public async getAll(): Promise<Lead[]>
  {
    return this.database
      .leads
      .orderBy('id')
      .toArray();
  }

  public async create(eventId: number, kioskId: number, {firstName, lastName, email, phone, submittedAt, uuid }: { firstName: string; lastName: string; uuid: string; submittedAt: number; phone?: string; email: string; }): Promise<number>
  {
    return this.database
      .leads
      .add({
        eventId,
        kioskId,
        uuid,
        firstName,
        lastName,
        email,
        phone,
        submittedAt,
        status: "pending",
      });
  }

  public async bulkUpdateKiosk(eventId: number, kioskId: number, leadIds: number[])
  {
    return this.database
      .leads
      .where(`id`)
      .anyOf(leadIds)
      .modify({
        eventId,
        kioskId,
      });
  }

  public async bulkUpdateStatus(status: LeadStatus,leadIds: number[])
  {
    return this.database
      .leads
      .where(`id`)
      .anyOf(leadIds)
      .modify({
        status,
      });
  }

  public async getTotalCount(): Promise<number>
  {
    return this.database
      .leads
      .count();
  }

  public async getTotalCountForKiosk(eventId: number, kioskId: number): Promise<number>
  {
    return this.database
      .leads
      .where({
        eventId,
        kioskId,
      })
      .count();
  }

  public async getPendingCount(): Promise<number>
  {
    return this.database
      .leads
      .where({
        status: `pending`
      })
      .count();
  }

  public async getPendingCountForKiosk(eventId: number, kioskId: number): Promise<number>
  {
    return this.database
      .leads
      .where({
        status: `pending`,
        eventId,
        kioskId,
      })
      .count();
  }

  public async getSendingCount(): Promise<number>
  {
    return this.database
      .leads
      .where({
        status: `sending`
      })
      .count();
  }

  public async getSendingCountForKiosk(eventId: number, kioskId: number): Promise<number>
  {
    return this.database
      .leads
      .where({
        status: `sending`,
        eventId,
        kioskId,
      })
      .count();
  }

  public async getFailedCount(): Promise<number>
  {
    return this.database
      .leads
      .where({
        status: `failed`
      })
      .count();
  }

  public async getFailedCountForKiosk(eventId: number, kioskId: number): Promise<number>
  {
    return this.database
      .leads
      .where({
        status: `failed`,
        eventId,
        kioskId,
      })
      .count();
  }

  public async updateStatus(id: number, status: LeadStatus): Promise<boolean>
  {
    try {
      this.database
        .leads
        .update(id, {status});
      return true;
    } catch (_error) {
      // unable to update status
      return false;
    }
  }

  public async addLeadSyncFailedStatus(id: number, error: unknown)
  {
    const syncError: LeadSyncError = {
      timestamp: Date.now(),
      error,
    }
    this.database
      .leads
      .where({
        id,
      })
      .modify((lead: Lead): Lead => {
        if (isUndefined(lead) || lead.id !== id){
          // shouldn't update
          throw new Error(`unable to update lead ${id}`);
        }


        return {
          ...lead,
          status: `failed`,
          syncErrors: [
            ...lead.syncErrors ?? [],
            syncError,
          ]
        };
      })
  }

  public async markFailedForRetry(): Promise<number>
  {

    return this.database
      .leads
      .where({
        status: `failed`
      })
      .modify({
        status: `pending`
      });
  }

  public async markSendingForRetry(): Promise<number>
  {

    return this.database
      .leads
      .where({
        status: `sending`
      })
      .modify({
        status: `pending`
      });
  }

  public async delete(id: number): Promise<void>
  {
    this.database
      .leads
      .delete(id);
  }

  public async getNextPending(): Promise<Lead | undefined>
  {
    const pendingCount = await this.getPendingCount();
    if (!pendingCount) {
      // nothing pending
      return undefined;

    }

    const useOffset = pendingCount > 1 ?
      Math.floor(Math.random() * (pendingCount - 1)) :
      0;

    return this.database
      .leads
      .where({
        status: `pending`
      })
      .offset(useOffset)
      .first();
  }
}

// @ts-ignore
// import {Wheel} from "spin-wheel/src/wheel";
import {Wheel} from "spin-wheel";
import {isUndefined} from "is-type-util";
import {ISpinWheelConfig,} from "./spin-wheel-config";
import {randomWeightedPrize} from "./random-weighted-prize";
import {ReduxStateService} from "../state/redux-state";
import {setSpinWheelResults} from "../../redux/lead-collection/lead-collection.slice";
import {defaultSpinWillConfig, SpinWheelItemValue} from "../../config/spin-wheel";


export class SpinWheelService {
  private spinWheel?: Wheel;
  private config?: ISpinWheelConfig;

  constructor() {
    this.setConfig(defaultSpinWillConfig);
  }

  private handleOnRest(event: {type: 'rest'; currentIndex: number; rotation: number;})
  {
    const value = this.config!.items[event.currentIndex].value;

    const isSpinAgain = value === SpinWheelItemValue.SpinAgain;

    ReduxStateService.dispatch(setSpinWheelResults({isSpinAgain, value}));
  }

  public setConfig(config: ISpinWheelConfig)
  {
    this.config = {
      ...config,
    };

    randomWeightedPrize.setItems(
      config.items
        .map(({weight, drawWeight, excludeFromDraw}, index) => {
        return {
          excludeFromDraw: excludeFromDraw ?? false,
          wheelIndex: index,
          weight: drawWeight ?? weight,
        };
      }))

    if (this.isAttached) {
      this.spinWheel
        ?.init(config);
    }
  }

  public get isAttached(): boolean
  {
    return (
      this.spinWheel
      && this.spinWheel.canvas !== null
    );
  }

  public detachContainer()
  {
    if (!this.isAttached) {
      // not already attached
      return;
    }
    this.spinWheel
      ?.remove()
  }
  public attachContainer(element: HTMLElement)
  {
    // remove any existing attached containers
    this.detachContainer();

    if (isUndefined(this.spinWheel)) {
      this.spinWheel = new Wheel(element, this.config);
      this.spinWheel.onRest = this.handleOnRest.bind(this);
    } else {
      this.spinWheel
        .add(element);
    }

    this.spinWheel
      .draw();

  }

  public spinToIndex(itemIndex: number)
  {

    const duration = 2600;
    const spinToCenter: boolean = true;
    const revolutions: number = 4;

    this.spinWheel?.spinToItem(
      itemIndex,
      duration,
      spinToCenter,
      revolutions
    );
  }

  public spin()
  {
    const randomItemIndex = randomWeightedPrize.getRandomWheelIndex();
    this.spinToIndex(randomItemIndex ?? 0);
  }

}

export const spinWheelService = new SpinWheelService();

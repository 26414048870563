
import {ILeadCollectionDisplayStore, type LeadCollectionReducer} from "../lead-collection.slice";

export interface ILeadCollectionDisplayModalPayload {
  modal: keyof ILeadCollectionDisplayStore;
  status: boolean;
}
export const setDisplayModalStatusReducer: LeadCollectionReducer<ILeadCollectionDisplayModalPayload> = (store, action) => {
  const {modal, status} = action.payload;

  return {
    ...store,
    display: {
      ...store.display,
      [modal]: status,
    }
  };
}

export const closeAllDisplayModalsReducer: LeadCollectionReducer = (store) => {
  return {
    ...store,
    display: undefined,
  };
}


import {FunctionComponent} from "react";
import {useSpinWheelContainerLogic} from "./SpinWheelContainer.logic";
import {SpinWheelResults} from "../results/SpinWheelResults";
import s from "./SpinWheelContainer.module.scss";
import {SpinWheelImageLoader} from "../image-loader/SpinWheelImageLoader";

export interface ISpinWheelContainer {

}

export const SpinWheelContainer: FunctionComponent<ISpinWheelContainer> = () => {
  const {spinWheelRef} = useSpinWheelContainerLogic();

  return (
    <div className={s.container}>
      <SpinWheelResults />
      <div ref={spinWheelRef} className={s.spinWheel}></div>
      <SpinWheelImageLoader />
    </div>
  );
}

import {AppState} from "../store";
import {DisplayModalsType, IDisplayStore, IDisplayStoreSettingsForm} from "./display.slice";
import {createSelector} from "@reduxjs/toolkit";

const getDisplayStore = (store: AppState): IDisplayStore =>
  store.display;

export const getDisplayActiveModals = (store: AppState): DisplayModalsType[] =>
  getDisplayStore(store).activeModals ?? [];

export const getIsDisplayModalActive = (store: AppState, displayModal: DisplayModalsType): boolean =>
  getDisplayActiveModals(store).includes(displayModal);

export const getIsSyncInfoModalActive = (store: AppState): boolean =>
  getIsDisplayModalActive(store, `sync-info`);

export const getIsSettingsModalActive = (store: AppState): boolean =>
  getIsDisplayModalActive(store, `settings`);
export const getIsSyncLeadsModalActive = (store: AppState): boolean =>
  getIsDisplayModalActive(store, `sync-leads`);

export const getDisplaySettingsForm = createSelector(
  [
    (store: AppState): IDisplayStoreSettingsForm | undefined => getDisplayStore(store).settingsForm,
  ],
  (settingsForm?: IDisplayStoreSettingsForm) => settingsForm ?? {},
);

export const getDisplaySettingsFormEventValue = (store: AppState): string =>
  `${getDisplaySettingsForm(store).eventId ?? ``}`;
export const getDisplaySettingsFormKioskValue = (store: AppState): string =>
  `${getDisplaySettingsForm(store).kioskId ?? ``}`;
export const getDisplaySettingsFormTokenValue = (store: AppState): string =>
  `${getDisplaySettingsForm(store).kioskToken ?? ``}`;

export const getDisplaySettingsFormValues = createSelector(
  [
    getDisplaySettingsFormEventValue,
    getDisplaySettingsFormKioskValue,
    getDisplaySettingsFormTokenValue
  ],
  (event, kiosk, token) => {
    return {
      event,
      kiosk,
      token,
    };
  }
)

import {ISpinWheelConfig, SpinWheelItemsConfigType} from "../services/spin-wheel/spin-wheel-config";
export enum SpinWheelItemValue {
  SampleBoost= `sample.boost`,
  SampleBoostBites= `sample.boostbites`,
  Swag= `item.swag`,
  SpinAgain = `action.retry`,
  None = `nothing`,
}

const spinWheelItemMessages: Record<SpinWheelItemValue, string> = {
  'sample.boost': `MIT45 Boost`,
  'sample.boostbites': `MIT45 Boost Bites`,
  'item.swag': `Swag`,
  'action.retry': `Spin Again`,
  'nothing': `Better Luck Next Time`,
};
export const getSpinWheelItemValueMessage = (value: SpinWheelItemValue): string =>
  spinWheelItemMessages[value];

export const defaultItemsConfig: SpinWheelItemsConfigType = [
  {
    // label: `Boost`,
    image: `/images/Boost.png`,
    imageScale: 0.2,
    imageRadius: 0.65,
    weight: 25,
    drawWeight: 15,
    value: SpinWheelItemValue.SampleBoost,
    backgroundColor: `#ccdac0`,
    // excludeFromDraw: true,
  },
  {
    label: `spin again`,
    weight: 12,
    drawWeight: 15,
    value: SpinWheelItemValue.SpinAgain,
    backgroundColor: `#cadb2b`,
  },
  {
    label: `Better luck next time`,
    labelColor: `#ffffff`,
    weight: 10,
    drawWeight: 10,
    value: SpinWheelItemValue.None,
    backgroundColor: `#000000`,
  },
  {
    // label: `Boost Bites`,
    image: `/images/BoostBites.png`,
    imageScale: 0.2,
    imageRadius: 0.65,
    weight: 25,
    value: SpinWheelItemValue.SampleBoostBites,
    backgroundColor: `#ccdac0`,
  },
  {
    label: `spin again`,
    weight: 12,
    drawWeight: 15,
    value: SpinWheelItemValue.SpinAgain,
    backgroundColor: `#cadb2b`,
  },
  {
    // label: `Swag`,
    image: `/images/Swag.png`,
    imageScale: 0.2,
    imageRadius: 0.65,
    weight: 15,
    drawWeight: 4,
    value: SpinWheelItemValue.Swag,
    backgroundColor: `#435a46`,
    // excludeFromDraw: true,
  },
];
export const defaultSpinWillConfig: ISpinWheelConfig = {
  debug: false,
  isInteractive: false,
  items: [
    ...defaultItemsConfig,
  ],
  itemBackgroundColors: ['#fff'],
  itemLabelRadiusMax: 0.3,
  overlayImage: `/images/spin-wheel-overlay-3.png`,
};

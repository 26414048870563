import type {ILeadCollectionSubmissionSpinStore, LeadCollectionReducer} from "../lead-collection.slice";


export interface ISubmitFormPayload {
  id: number;
  uuid: string;
  submittedAt: number;
}
export const submitFormReducer: LeadCollectionReducer<ISubmitFormPayload> = (store, action) => {

  const {id, uuid, submittedAt} = action.payload;
  return {
    ...store,
    submitDetails: {
      id,
      uuid,
      submittedAt,
    },
  };
};

export const setSpinWheelShouldSpinReducer: LeadCollectionReducer<undefined> = (store) => {

  return {
    ...store,
    submitDetails: {
      ...store.submitDetails!,
      spin: {
        ...store.submitDetails?.spin,
        shouldSpin: true,
      },
    },
    display: {
      ...store.display,
      isSpinWheelModalOpen: true,
    }
  };
};
export const setPostSubmitPrizeSpinResultsReducer: LeadCollectionReducer<ILeadCollectionSubmissionSpinStore> = (store, action) => {
  const spin = action.payload;
  return {
    ...store,
    submitDetails: {
      ...store.submitDetails!,
      spin,
    },
  };
};

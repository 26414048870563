import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {isUndefined} from "is-type-util";


export type DisplayModalsType = "sync-info" |
  "settings" | "sync-leads";

export interface IDisplayStoreSettingsForm {
  eventId?: number;
  kioskId?: number;
  kioskToken?: string;
}
export interface IDisplayStore {
  settingsForm?: IDisplayStoreSettingsForm;
  activeModals?: DisplayModalsType[]
}

const initialState: IDisplayStore = {
};

export const displaySlice = createSlice({
  name: `display`,
  initialState,
  reducers: {
    activateModal: (state, action: PayloadAction<DisplayModalsType>) => {
      const modalToActivate = action.payload;

      return {
        ...state,
        activeModals: [
          ...state.activeModals ?? [],
          modalToActivate,
        ],
      };
    },
    deactivateModal: (state, action: PayloadAction<DisplayModalsType>) => {
      const modalToDeactivate = action.payload;
      const activeModals = [ ...(state.activeModals ?? []),]
        .filter((activeModal) => activeModal !== modalToDeactivate);

      return {
        ...state,
        activeModals: [
          ...activeModals,
        ],
      };
    },

    resetSettingsForm: (state) => {
      return {
        ...state,
        settingsForm: {
          ...initialState.settingsForm,
        }
      };
    },

    setSettingsFormField: (state, action: PayloadAction<{field: keyof IDisplayStoreSettingsForm; value?: string}>) => {
      const {field, value} = action.payload;

      let updateToValue: string | number | undefined = value;

      if (isUndefined(value) || value === ``){
        // value is empty
        updateToValue = undefined;

      } else if (field === `eventId` || field === `kioskId`) {
        // value should be a number
        updateToValue = parseInt(value, 10);
      }

      return {
        ...state,
        settingsForm: {
          ...state.settingsForm,
          [field]: updateToValue,
        },
      };

    }
  },
});


export const {
  activateModal,
  deactivateModal,

  resetSettingsForm,
  setSettingsFormField,
} = displaySlice.actions;
export const displayReducer = displaySlice.reducer;



import Modal from "../modal/Modal";
import {useSpinWheelModalLogic} from "./SpinWheelModal.logic";
import {SpinWheelContainer} from "../../spin-wheel/container/SpinWheelContainer";
export const SpinWheelModal = () => {
  const {isOpen, handleClose} = useSpinWheelModalLogic();
  return (
    <Modal
      title={`Spin to Win`}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <SpinWheelContainer />
    </Modal>
  )
}

import {useAppSelector} from "../../../redux/hooks";
import {getIsSpinWheelEnabled} from "../../../redux/lead-collection/lead-collection.selectors";
import {kioskService} from "../../../services/event/kiosk-service";


export interface ISpinWheelSettingsLogic {
  isChecked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) =>  void;
}

export const useSpinWheelSettingsLogic = (): ISpinWheelSettingsLogic => {
  const isChecked = useAppSelector(getIsSpinWheelEnabled);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    kioskService.setSpinWheelEnabled(event.target.checked);
  };
  return {
    isChecked,
    handleChange,
  };
}

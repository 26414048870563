import {useAppSelector} from "../../../redux/hooks";
import {
  getLeadCollectionEventId, getLeadCollectionIsAuthenticated,
  getLeadCollectionKioskId
} from "../../../redux/lead-collection/lead-collection.selectors";


export interface ISettingsCurrentLogic {
  isAuthenticated: boolean;
  eventId?: number;
  kioskId?: number;
}

export const useSettingsCurrentLogic = (): ISettingsCurrentLogic => {
  const isAuthenticated = useAppSelector(getLeadCollectionIsAuthenticated);
  const eventId = useAppSelector(getLeadCollectionEventId);
  const kioskId = useAppSelector(getLeadCollectionKioskId);
  return {
    isAuthenticated,
    eventId,
    kioskId,
  };
}

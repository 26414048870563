import {AbstractPulseClient} from "./abstract-client";
import type {IPulseClientProvider} from "./providers/pulse-client-provider";

export interface IEventLocationData {
  name?: string;
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface IEventCreateData {
  name: string;
  description?: string;
  location?: IEventLocationData;
  start_date: RequestDateType;
  end_date: RequestDateType;
}
export interface IEventUpdateData {
  name?: string;
  description?: string;
  location?: IEventLocationData;
  start_date?: RequestDateType;
  end_date?: RequestDateType;
}
export class EventsClient extends AbstractPulseClient <number, IEventCreateData, IEventUpdateData> {

  constructor(provider?: IPulseClientProvider) {
    super(`/events`, provider);
  }

}

import {initialState} from "../lead-collection.initialState";
import type {
  LeadCollectionReducer,
  LeadCollectionStoreField
} from "../lead-collection.slice";

export const resetFieldReducer: LeadCollectionReducer<LeadCollectionStoreField> = (store, action) => {
  const fieldName = action.payload
  return {
    ...store,
    values: {
      ...store.values,
      // reset field value to initial
      [fieldName]: initialState.values?.[fieldName],
    },
    errors: {
      ...store.errors,
      // reset field error to initial
      [fieldName]: initialState.errors?.[fieldName],
    },
  };
};

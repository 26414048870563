import {FunctionComponent} from "react";
import {defaultItemsConfig, defaultSpinWillConfig} from "../../../config/spin-wheel";
import s from "./SpinWheelImageLoader.module.scss";
import {isDefined} from "is-type-util";
import {getEnv} from "../../../config/environment";


const spinWheelImages = defaultItemsConfig
  .map(({image}) => image)
  .filter(isDefined);

if (isDefined(defaultSpinWillConfig.overlayImage)){
  spinWheelImages.push(defaultSpinWillConfig.overlayImage);
}

export const SpinWheelImageLoader: FunctionComponent = () => {
  return (
    <div className={s.container}>
      {spinWheelImages.map((imgSrc, index) => {
        return <img key={`wheel-image-${index}`} src={getEnv(`PUBLIC_URL`) + imgSrc} alt={`wheel ${imgSrc}`} />
      })}
    </div>
  )
}

import {useLiveQuery} from "dexie-react-hooks";
import {KioskLeadRepository} from "../../../repositories/events/kiosk-lead-repository";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {useEffect} from "react";
import {resetSync, updateStats} from "../../../redux/sync/sync.slice";
import {
  getLeadCollectionEventId,
  getLeadCollectionIsAuthenticated, getLeadCollectionKioskId
} from '../../../redux/lead-collection/lead-collection.selectors';

const kioskLeadRepository = new KioskLeadRepository();
export interface ISyncInfoConnectorLogic {

}

export const useSyncInfoConnectorLogic = (): ISyncInfoConnectorLogic => {

  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(getLeadCollectionIsAuthenticated);
  const eventId = useAppSelector(getLeadCollectionEventId);
  const kioskId = useAppSelector(getLeadCollectionKioskId);

  const pendingCount = useLiveQuery<number>(() => {
    if (!isAuthenticated) {
      return kioskLeadRepository.getPendingCount();
    }
    return kioskLeadRepository.getPendingCountForKiosk(eventId!, kioskId!);
  }, [isAuthenticated, eventId, kioskId]) ?? 0;

  const sendingCount = useLiveQuery<number>(() => {
    if (!isAuthenticated) {
      return kioskLeadRepository.getSendingCount();
    }
    return kioskLeadRepository.getSendingCountForKiosk(eventId!, kioskId!);
  }, [isAuthenticated, eventId, kioskId]) ?? 0;

  const failedCount = useLiveQuery<number>(() => {
    if (!isAuthenticated) {
      return kioskLeadRepository.getFailedCount();
    }
    return kioskLeadRepository.getFailedCountForKiosk(eventId!, kioskId!);
  }, [isAuthenticated, eventId, kioskId]) ?? 0;

  const totalCount = useLiveQuery<number>(() => {
    if (!isAuthenticated) {
      return kioskLeadRepository.getTotalCount();
    }
    return kioskLeadRepository.getTotalCountForKiosk(eventId!, kioskId!);
  }, [isAuthenticated, eventId, kioskId]) ?? 0;

  const completeCount = totalCount - (pendingCount + sendingCount + failedCount);



  useEffect(() => {

    dispatch(updateStats({
      pending: pendingCount,
      sending: sendingCount,
      failed: failedCount,
      total: totalCount,
      complete: completeCount,
    }));

    return () => {
      dispatch(resetSync());
    };

  }, [dispatch, pendingCount, sendingCount, failedCount, totalCount, completeCount]);



  return {

  };
}

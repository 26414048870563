import React from 'react';
import {ReduxProvider} from "./redux/ReduxProvider";
import {KioskPortal} from "./components/kiosk-portal/KioskPortal";
import {SyncInfoConnector} from "./components/connections/sync-info/SyncInfoConnector";
import {SyncConnector} from "./components/connections/sync/SyncConnector";


function App() {
  return (
    <ReduxProvider>
      <KioskPortal />
      <SyncInfoConnector/>
      <SyncConnector/>
    </ReduxProvider>
  );
}

export default App;

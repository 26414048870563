import {ChangeEvent, FormEvent, useCallback} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {getDisplaySettingsForm, getDisplaySettingsFormValues} from "../../../redux/display/display.selectors";
import {deactivateModal, resetSettingsForm, setSettingsFormField} from "../../../redux/display/display.slice";
import {kioskService} from "../../../services/event/kiosk-service";
import {activeKioskService} from "../../../services/event/active-kiosk-service";


export interface ISettingsFormData {
  event: string;
  kiosk: string;
  token: string;
}

export interface ISettingsFormLogic {
  data: ISettingsFormData;
  handleReset: () => void | Promise<void>;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void | Promise<void>;

  handleEventChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleKioskChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleTokenChange: (event: ChangeEvent<HTMLInputElement>) => void;

}

export const useSettingsFormLogic = (): ISettingsFormLogic => {
  const dispatch = useAppDispatch();
  const settingsFormValues = useAppSelector(getDisplaySettingsForm);
  const data = useAppSelector<ISettingsFormData>(getDisplaySettingsFormValues);

  const handleReset = useCallback(() => {
    dispatch(resetSettingsForm());
  }, [dispatch]);

  const handleSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.currentTarget.checkValidity();
    // pass to kiosk and attempt to validate
    const {kioskId, kioskToken, eventId} = settingsFormValues;
    kioskService.authenticate(eventId!, kioskId!, kioskToken)
      .then((isAuthenticated) => {
        if (isAuthenticated){
          // set as active authenticated kiosk
          activeKioskService.setActiveKioskId(kioskId!);
          // clear form
          dispatch(resetSettingsForm());
          // close settings modal
          dispatch(deactivateModal(`settings`));
        }
      });
  }, [dispatch, settingsFormValues]);

  const handleEventChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSettingsFormField({field: `eventId`, value: event.currentTarget.value}));
  }, [dispatch]);
  const handleKioskChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSettingsFormField({field: `kioskId`, value: event.currentTarget.value}));
  }, [dispatch]);
  const handleTokenChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSettingsFormField({field: `kioskToken`, value: event.currentTarget.value}));
  }, [dispatch]);
  return {
    data,
    handleEventChange,
    handleKioskChange,
    handleTokenChange,
    handleReset,
    handleSubmit,
  };
}

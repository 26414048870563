import {FunctionComponent} from "react";
import {useConnectionStatusIconLogic} from "./ConnectionStatusIcon.logic";
import WifiOffIcon from '@mui/icons-material/WifiOff';
import WifiIcon from '@mui/icons-material/Wifi';

export const ConnectionStatusIcon: FunctionComponent = () => {
  const {isConnected} = useConnectionStatusIconLogic();

  if (!isConnected) {
    return <WifiOffIcon />;
  }
  return <WifiIcon />;

}

import {createSlice, type PayloadAction} from "@reduxjs/toolkit";

export interface IConnectionStore {
  browserStatus?: boolean;
  serverPingStatus?: boolean;
}

const initialState: IConnectionStore = {};

export const connectionSlice = createSlice({
  name: `connection`,
  initialState,
  reducers: {
    setBrowserStatus: (store, action: PayloadAction<boolean>) => {
      const browserStatus = action.payload;
      return {
        ...store,
        browserStatus
      };
    },
    setServerPingStatus: (store, action: PayloadAction<boolean>) => {
      const serverPingStatus = action.payload;
      return {
        ...store,
        serverPingStatus,
      };
    },
  }
});

export const {setBrowserStatus, setServerPingStatus} = connectionSlice.actions;

export const connectionReducer = connectionSlice.reducer;

import {FunctionComponent} from "react";
import {useSyncInfoLogic} from "./SyncInfo.logic";
import s from "./SyncInfo.module.scss";
import Button from "@mui/material/Button";
import {kioskSyncService} from "../../services/event/kiosk-sync/kiosk-sync-service";
import {manageLeadsFeatureFlag} from "../../config/feature-flags";


const handleReTrySyncErrors = () => {
  void kioskSyncService.queueFailedLeadsForSync();
}

export const SyncInfo: FunctionComponent = () => {
  const {failed, total, pending, sending, isConnected, handleOpenLeadsModal} = useSyncInfoLogic();

  return (
    <div className={s.container}>
      <div>Connection: {isConnected ? `Online` : `Offline`}</div>
      <div>Status: {pending}[{sending}] / {total}</div>
      {!!failed && (
        <div>
          <h2>Errors:</h2>
          <Button color="error" variant="outlined" onClick={handleReTrySyncErrors}> retry {failed} failed</Button>
        </div>
      )}
      {manageLeadsFeatureFlag && <Button color={"info"} variant={"contained"} onClick={handleOpenLeadsModal} >Manage Leads</Button>}
    </div>
  );
}

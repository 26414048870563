import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {
  getSyncCompleteStat,
  getSyncFailedStat,
  getSyncPendingStat,
  getSyncSendingStat,
  getSyncTotalStat
} from "../../redux/sync/sync.selectors";
import {getIsConnected} from "../../redux/connection/connection.selectors";
import {useCallback} from "react";
import {activateModal} from "../../redux/display/display.slice";

export interface ISyncInfoLogic {pending: number;
  sending: number;
  failed: number;
  complete: number;
  total: number;
  isConnected: boolean;
  handleOpenLeadsModal: () => void;
}

export const useSyncInfoLogic = (): ISyncInfoLogic => {

  const pending = useAppSelector(getSyncPendingStat);
  const sending  = useAppSelector(getSyncSendingStat);
  const failed  = useAppSelector(getSyncFailedStat);
  const  total = useAppSelector(getSyncTotalStat);
  const complete = useAppSelector(getSyncCompleteStat);

  const isConnected = useAppSelector(getIsConnected);

  const dispatch = useAppDispatch();

  const handleOpenLeadsModal = useCallback(() => {
    dispatch(activateModal(`sync-leads`))
  }, [dispatch]);

  return {
    pending,
    sending,
    failed,
    total,
    complete,
    isConnected,
    handleOpenLeadsModal,
  }
}

import {EventRepository} from "../../repositories/events/event-repository";
import {KioskRepository} from "../../repositories/events/kiosk-repository";
import {Kiosk} from "../../database/models/Kiosk";
import {Event} from "../../database/models/Event";
import {EventsClient} from "../../clients/pulse/events-client";
import {EventKiosksClient} from "../../clients/pulse/event-kiosks-client";
import {isDefined, isUndefined} from "is-type-util";
import {EventKioskProvider} from "../../clients/pulse/providers/event-kiosk-provider";
import {productMonitoring} from "../logging/product-monitoring";
import {setIsSpinWheelEnabled, setKioskAuthentication} from "../../redux/lead-collection/lead-collection.slice";
import {ReduxStateService} from "../state/redux-state";
import {localStorageService} from "../storage/local-storage";

const spinWheelEnabledStorageKey = `IS_SPIN_WHEEL_ENABLED`;

export class KioskService {

  private event?: Event;
  private kiosk?: Kiosk;
  private eventClient?: EventsClient;
  private kioskClient?: EventKiosksClient;


  constructor(
    private eventRepository: EventRepository = new EventRepository(),
    private kioskRepository: KioskRepository = new KioskRepository(),
  ) {
  }


  private async loadFromLocal(eventId: number, kioskId: number)
  {
    const [localEvent, localKiosk] = await Promise.all([
      this.eventRepository
        .getForId(eventId),
      this.kioskRepository
        .getForId(kioskId)
    ]);
    this.event = localEvent;
    this.kiosk = localKiosk;
    this.setSpinWheelEnabled();
  }

  private get isSpinWheelEnabled(): boolean
  {
    return localStorageService.getJsonItem<boolean>(spinWheelEnabledStorageKey) ?? true;
  }
  private set isSpinWheelEnabled(value: boolean)
  {
    localStorageService.setJsonItem<boolean>(spinWheelEnabledStorageKey, value);
  }

  public setSpinWheelEnabled(value?: boolean)
  {
    if (isDefined(value)){
      this.isSpinWheelEnabled = value;
    }

    void ReduxStateService.dispatch(
      setIsSpinWheelEnabled(value ?? this.isSpinWheelEnabled)
    );
  }

  private async ensureEventLoaded(eventId: number)
  {
    if (isDefined(this.event) && this.event.id === eventId) {
      // already defined
      return;
    }
    this.setSpinWheelEnabled();
    const eventResponse = await this.eventClient!
      .getOne<{data: Event}>(eventId);
    const event = eventResponse.data.data;
    this.event = await this.eventRepository
      .create(eventId, event.name);
  }

  private async ensureKioskLoaded(eventId: number, kioskId: number, kioskToken: string)
  {
    if (isDefined(this.kiosk)) {
      // already defined
      return;
    }

    // save current to kiosk
    this.kiosk = await this.kioskRepository
      .create(eventId, kioskId, kioskToken);
  }


  private async ensureKioskSetup(eventId: number, kioskId: number, kioskToken?: string)
  {
    productMonitoring.log(`KioskService.ensureKioskSetup`, {eventId, kioskId, kioskToken});
    await this.loadFromLocal(eventId, kioskId);

    const clientToken = kioskToken ??
      this.kiosk?.token;
    productMonitoring.log(`KioskService.ensureKioskSetup`, {clientToken});
    if (isUndefined(clientToken)) {
      throw new Error(`unable to authenticate without token`);
    }

    // setup clients
    const authenticatedProvider = new EventKioskProvider(clientToken);

    this.eventClient = new EventsClient(authenticatedProvider);
    this.kioskClient = new EventKiosksClient(eventId, authenticatedProvider);

    await Promise.all([
      this.ensureEventLoaded(eventId),
      this.ensureKioskLoaded(eventId, kioskId, clientToken),
    ]);
    productMonitoring.log(`KioskService.ensureKioskSetup - complete`);
  }

  public async authenticate(eventId: number, kioskId: number, kioskToken?: string): Promise<boolean>
  {
    productMonitoring.log(`KioskService.authenticate`, {eventId, kioskId, kioskToken});

    // make sure it is setup
    await this.ensureKioskSetup(eventId, kioskId, kioskToken);

    // make sure both event and kiosk are loaded to db
    const isAuthenticated = (
      isDefined(this.event)
      && isDefined(this.kiosk)
    );

    if (isAuthenticated) {
      void ReduxStateService.dispatch(setKioskAuthentication({
        isAuthenticated,
        eventId,
        kioskId,
      }));
    } else {
      void ReduxStateService.dispatch(setKioskAuthentication({
        isAuthenticated,
      }));
    }

    // TODO: do we want to use api request to test?

    return isAuthenticated;
  }

}

export const kioskService = new KioskService();

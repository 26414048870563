import {FunctionComponent} from "react";
import Modal from "../modal/Modal";
import {useSyncInfoModalLogic} from "./SyncInfoModal.logic";
import {SyncInfo} from "../../sync-info/SyncInfo";


export const SyncInfoModal: FunctionComponent = () => {
  const {isOpen, handleClose} = useSyncInfoModalLogic();
  return (
    <Modal
      title={`Sync Details`}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <SyncInfo />
    </Modal>
  );
}


export type EnvironmentVariableKey = "NODE_ENV" |
  "PUBLIC_URL" |
  "REACT_APP_VERSION" |
  "REACT_APP_BUILD_PROVIDER" |
  "REACT_APP_VERSION_MAJOR" |
  "REACT_APP_VERSION_MINOR" |
  "REACT_APP_VERSION_PATCH" |
  "REACT_APP_BUILD_NUMBER" |
  "REACT_APP_BUILD_START_TIME";
export const environmentConfig = process.env;

export const getEnv = (key: EnvironmentVariableKey) =>
  environmentConfig[key] ?? undefined;

export const buildConfig = {
  version: {
    major: getEnv(`REACT_APP_VERSION_MAJOR`),
    minor: getEnv(`REACT_APP_VERSION_MINOR`),
    patch: getEnv(`REACT_APP_VERSION_PATCH`),
    build: getEnv(`REACT_APP_BUILD_NUMBER`),
    full: getEnv(`REACT_APP_VERSION`),
  },
  build: {
    provider: getEnv(`REACT_APP_BUILD_PROVIDER`),
    timestamp: getEnv(`REACT_APP_BUILD_START_TIME`),
  }
};



export const buildVersion = `v${buildConfig.version.full}`;

import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
  getIsLeadCollectionThankYouModalOpen,
  getIsSpinWheelEnabled
} from "../../../redux/lead-collection/lead-collection.selectors";
import {useCallback} from "react";
import {
  resetLeadData,
  setSpinWheelShouldSpin
} from "../../../redux/lead-collection/lead-collection.slice";

export interface IThankYouModalLogic {
  isOpen: boolean;
  handleClose?: () => void;
  actionLabel?: string;
  handleAction?: () => void;

  hasSpinWheel: boolean;
}

export const useThankYouModalLogic = (): IThankYouModalLogic => {

  const isOpen = useAppSelector(getIsLeadCollectionThankYouModalOpen);

  const dispatch = useAppDispatch();
  const closeModalHandler = useCallback(() => {
    dispatch(resetLeadData());
  }, [dispatch]);

  const openSpinWheelHandler = useCallback(() => {

    dispatch(setSpinWheelShouldSpin());
  }, [dispatch]);

  const hasSpinWheel = useAppSelector(getIsSpinWheelEnabled);

  const handleClose = (hasSpinWheel ? undefined : closeModalHandler);
  const handleAction = (hasSpinWheel ? openSpinWheelHandler : undefined);
  const actionLabel = (hasSpinWheel ? `Spin To Win` : undefined);

  return {
    isOpen,
    handleClose,
    handleAction,
    actionLabel,
    hasSpinWheel,
  };
}

import Dexie, {type Table, type Transaction} from "dexie";
import {Event} from "./models/Event";
import {Kiosk} from "./models/Kiosk"
import {Lead} from "./models/Lead";


export interface IEventDatabaseTransaction extends Transaction {
  events: Table<Event, number>;
  kiosks: Table<Kiosk, number>;
  leads: Table<Lead, number>;
}

const populateEventDatabase = async (trans: Transaction) => {
  const _databaseTransaction = (trans as IEventDatabaseTransaction);
  // TODO: do we need to seed anything in the DB on first run?
}
export class EventDatabase extends Dexie {
  events!: Table<Event, number>;
  kiosks!: Table<Kiosk, number>;
  leads!: Table<Lead, number>;



  constructor() {
    super(`event-kiosk`);
    this.applyMigrations();
    this.on("populate", populateEventDatabase)
  }

  private applyMigrations(): void
  {
    this.version(1)
      .stores({
        events: `id, name`,
        kiosks: `id, eventId`,
        leads: `++id, eventId, kioskId, uuid, status`
      });
    this.version(2)
      .stores({
        logs: `++id, type, timestamp, [type+timestamp]`,
      });
  }
}
export const eventDatabaseInstance = new EventDatabase();

// open database so migrations run
eventDatabaseInstance.open();

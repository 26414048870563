import {useLiveQuery} from "dexie-react-hooks";
import {KioskLeadRepository} from "../../../repositories/events/kiosk-lead-repository";
import {Lead} from "../../../database/models/Lead";
import {GridRowSelectionModel, useGridApiRef} from "@mui/x-data-grid";
import {MutableRefObject, useLayoutEffect, useState} from "react";
import {GridApiCommunity} from "@mui/x-data-grid/models/api/gridApiCommunity";
import {isUndefined} from "is-type-util";


export interface ILeadsListLogic {
  isLoading: boolean;
  leads?: Lead[];
  apiRef: MutableRefObject<GridApiCommunity>;
}

const leadRepository = new KioskLeadRepository();
export const useLeadsListLogic = (): ILeadsListLogic => {

  const leads = useLiveQuery<Lead[]>(() => leadRepository.getAll() ?? []);
  const isLoading = isUndefined(leads);



  const apiRef = useGridApiRef();


  return {
    isLoading,
    leads,
    apiRef,
  };
};

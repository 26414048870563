import type {ILeadCollectionStore, LeadCollectionReducer} from "../lead-collection.slice";
import {SystemCollectorType} from "../../system/system.slice";
import {leadCollectionConfig} from "../../../config/lead-collection";

export const setFormCollectorReducer: LeadCollectionReducer<SystemCollectorType> = (store, action): ILeadCollectionStore => {
  const collector = action.payload;

  return {
    ...store,
    collector,
    shouldFormAutocomplete: leadCollectionConfig.autoComplete[collector],
  };
}
